// @flow

export const ssrHeadersForAPIServer = (
  request: http$IncomingMessage<>,
): { [string]: string, ... } => {
  const result: { [string]: string, ... } = {
    origin:
      // forwarded host is always defined on our server so we use it
      // (worse part that its true only for our server)
      request.headers['x-forwarded-host'] != null
        ? `https://${request.headers['x-forwarded-host']}`
        : `http://${request.headers['host']}`,
  };

  if (request.headers.referer != null) {
    result.referer = request.headers.referer;
  }

  if (request.headers.cookie != null) {
    result.cookie = request.headers.cookie;
  }

  if (request.headers['user-agent'] != null) {
    result['user-agent'] = request.headers['user-agent'];
  }

  if (request.headers['x-cloud-trace-context'] != null) {
    result['x-cloud-trace-context'] = request.headers['x-cloud-trace-context'];
  }

  return result;
};
