// @flow

/* eslint-env commonjs */

/*
 * Don't forget about flow annotations in comments.
 *
 * To add the new language you need to update all cases in this file
 * and react on flow errors in language.server.js and Layout.js.
 */

/*::
import typeof DateLocale from 'date-fns/esm/locale/en-US';

import type { CountryDomain, CountryCode } from './server/UserData.js';

export type Language = 'en' | 'fr' | 'de' | 'it' | 'es';

export type PrismicLanguage = 'en-gb' | 'fr-ch' | 'de-ch' | 'it-ch' | 'en-fr' | 'fr-fr' | 'de-de' | 'en-ch' | 'en-es' | 'es-es' | 'it-it';

export type { DateLocale };
*/

const list /*: $ReadOnlyArray<Language>*/ = ['en', 'fr', 'de', 'it', 'es'];
const listCh /*: $ReadOnlyArray<Language>*/ = ['en', 'fr', 'de', 'it'];
const listFr /*: $ReadOnlyArray<Language>*/ = ['fr'];
// const listFr /*: $ReadOnlyArray<Language>*/ = ['en', 'fr'];
const listDe /*: $ReadOnlyArray<Language>*/ = ['de'];
const listEs /*: $ReadOnlyArray<Language>*/ = ['es'];
const listGb /*: $ReadOnlyArray<Language>*/ = ['en'];
const listIt /*: $ReadOnlyArray<Language>*/ = ['it'];

const prodList /*:$ReadOnlyArray<Language>*/ = ['en', 'fr', 'de', 'it', 'es'];

const intlLocales /*:{[Language]: string,...}*/ = {
  en: 'en-GB',
  // use de-CH for fr, it as seems like intl dont know fr-CH, it-CH
  fr: 'de-CH',
  de: 'de-CH',
  it: 'de-CH',
  es: 'es-CH',
};
const intlLocalesFr /*:{[Language]: string,...}*/ = {
  //en: 'en-GB',
  fr: 'fr-FR',
};
const intlLocalesDe /*:{[Language]: string,...}*/ = {
  de: 'de-DE',
};

const intlLocalesEs /*:{[Language]: string,...}*/ = {
  es: 'es-ES',
};

const intlLocalesIt /*:{[Language]: string,...}*/ = {
  it: 'it-IT',
};

// Should be in sync with API's functions.js
const prismicLocales /*:{[Language]: $ReadOnlyArray<PrismicLanguage>,...}*/ = {
  en: ['en-ch', 'en-gb', 'en-es'],
  fr: ['fr-ch', 'fr-fr'],
  de: ['de-ch', 'de-de'],
  it: ['it-ch', 'it-it'],
  es: ['es-es'],
};

const dateFnsLocales /*:{[Language]: string,...}*/ = {
  en: 'en-US',
  fr: 'fr',
  de: 'de',
  it: 'it',
  es: 'es',
};

const trustPilotLocales /*:{ [Language]: {| locale: string, uri: string |}, ...}*/ =
  {
    en: {
      locale: 'en-GB',
      uri: 'https://uk.trustpilot.com/review/realadvisor.ch',
    },
    fr: {
      locale: 'fr-FR',
      uri: 'https://uk.trustpilot.com/review/realadvisor.ch',
    },
    de: {
      locale: 'de-CH',
      uri: 'https://ch.trustpilot.com/review/realadvisor.ch',
    },
    it: {
      locale: 'it-IT',
      uri: 'https://it.trustpilot.com/review/realadvisor.ch',
    },
    es: {
      locale: 'es-ES',
      uri: 'https://es.trustpilot.com/review/realadvisor.ch',
    },
  };

const languageHosts /*:{ [string]: string, ...}*/ = {
  'en-ch': 'realadvisor.ch',
  'fr-ch': 'realadvisor.ch',
  'en-gb': 'realadvisor.co.uk',
  'de-ch': 'realadvisor.ch',
  'it-ch': 'realadvisor.ch',
  'fr-fr': 'realadvisor.fr',
  'es-es': 'realadvisor.es',
  'de-de': 'realadvisor.de',
  'it-it': 'realadvisor.it',
};

const languageCountries /*:{ [string]: 'ch' | 'fr' | 'de' | 'es' | 'gb' | 'it', ... }*/ =
  {
    'en-ch': 'ch',
    'fr-ch': 'ch',
    'en-gb': 'gb',
    'de-ch': 'ch',
    'it-ch': 'ch',
    'fr-fr': 'fr',
    'es-es': 'es',
    'de-de': 'de',
    'it-it': 'it',
  };

const countryDefaultLanguages /*:{ ['ch' | 'fr' | 'de' | 'es' | 'gb' | 'it' ]: string, ... }*/ =
  {
    ch: 'en-ch',
    fr: 'fr-fr',
    de: 'de-de',
    es: 'es-es',
    gb: 'en-gb',
    it: 'it-it',
  };

const normalLanguages = {
  'en-gb': 'en',
  'fr-ch': 'fr',
  'fr-fr': 'fr',
  'de-ch': 'de',
  'it-ch': 'it',
  'de-de': 'de',
  'es-es': 'es',
  'en-ch': 'en',
  'it-it': 'it',
};

const referenceLanguageForCountry = (
  countryDomain /*: CountryDomain*/,
) /* :Language */ => {
  switch (countryDomain) {
    case 'es':
      return 'es';
    case 'de':
      return 'de';
    case 'it':
      return 'it';
    case 'fr':
      return 'fr';
    default:
      return 'en';
  }
};

const setDateLocales = (dateLocales /*: { [Language]: DateLocale,... }*/) => {
  global.dateLocales = dateLocales;
};

const getDateLocale = (language /*: Language*/) /*: DateLocale*/ => {
  if (typeof window !== 'undefined') {
    return window.i18nLocale;
  } else {
    return global.dateLocales[language];
  }
};

const getLocale = (
  countryDomain /*: CountryDomain*/,
  language /*: Language*/,
) /*: string*/ =>
  (countryDomain === 'fr'
    ? intlLocalesFr[language]
    : countryDomain === 'de'
    ? intlLocalesDe[language]
    : countryDomain === 'es'
    ? intlLocalesEs[language]
    : countryDomain === 'it'
    ? intlLocalesIt[language]
    : intlLocales[language]) || language;

const getSupportedLanguageOrFallback = (
  language /*: string*/,
  fallback /*: Language*/,
) /*: Language*/ => {
  if (
    language === 'en' ||
    language === 'fr' ||
    language === 'de' ||
    language === 'it' ||
    language === 'es'
  ) {
    return language;
  }
  return fallback;
};

const getLanguageListForCountry = (
  countryDomain /*: CountryDomain*/,
) /*: $ReadOnlyArray<Language>*/ => {
  switch (countryDomain) {
    case 'ch':
      return listCh;
    case 'fr':
      return listFr;
    case 'es':
      return listEs;
    case 'de':
      return listDe;
    case 'gb':
      return listGb;
    case 'it':
      return listIt;
    default:
      return ['en'];
  }
};

const getNamespaceListForCountry = (
  countryDomain /*: CountryDomain*/,
) /*: $ReadOnlyArray<string>*/ => {
  switch (countryDomain) {
    case 'ch':
      return ['common'];
    case 'fr':
      return ['common', 'fr'];
    case 'es':
      return ['common'];
    case 'de':
      return ['common', 'de'];
    case 'gb':
      return ['common'];
    case 'it':
      return ['common', 'it'];
    default:
      return ['common'];
  }
};

const stringToCountryDomain = (
  string /* : ?string */,
) /* : CountryDomain */ => {
  switch (string) {
    case 'fr':
      return 'fr';
    case 'ch':
      return 'ch';
    case 'de':
      return 'de';
    case 'es':
      return 'es';
    case 'gb':
      return 'gb';
    case 'it':
      return 'it';
    default:
      return 'ch';
  }
};

const getLanguagesFromCountryCode = (
  countryCode /*: CountryCode */,
) /* : $ReadOnlyArray<Language> */ => {
  switch (countryCode) {
    case 'CH':
      return listCh;
    case 'FR':
      return listFr;
    case 'DE':
      return listDe;
    case 'ES':
      return listEs;
    case 'IT':
      return listIt;
    case 'GB':
      return listGb;
    default:
      return listCh;
  }
};
exports.list = list;
exports.listCh = listCh;
exports.listFr = listFr;
exports.listDe = listDe;
exports.listEs = listEs;
exports.listGb = listGb;
exports.listIt = listIt;
exports.prodList = prodList;
exports.intlLocales = intlLocales;
exports.intlLocalesFr = intlLocalesFr;
exports.intlLocalesDe = intlLocalesDe;
exports.intlLocalesEs = intlLocalesEs;
exports.intlLocalesIt = intlLocalesIt;
exports.prismicLocales = prismicLocales;
exports.dateFnsLocales = dateFnsLocales;
exports.trustPilotLocales = trustPilotLocales;
exports.setDateLocales = setDateLocales;
exports.getDateLocale = getDateLocale;
exports.getLocale = getLocale;
exports.getSupportedLanguageOrFallback = getSupportedLanguageOrFallback;
exports.getLanguageListForCountry = getLanguageListForCountry;
exports.getNamespaceListForCountry = getNamespaceListForCountry;
exports.referenceLanguageForCountry = referenceLanguageForCountry;
exports.stringToCountryDomain = stringToCountryDomain;
exports.languageHosts = languageHosts;
exports.languageCountries = languageCountries;
exports.normalLanguages = normalLanguages;
exports.countryDefaultLanguages = countryDefaultLanguages;
exports.getLanguagesFromCountryCode = getLanguagesFromCountryCode;
