// @flow

import * as React from 'react';
import { useRouter as useNextRouter } from 'next/router';
import { toPageHref } from '../../utils/rewrites';
import { useUserData } from '../../hooks/user-data';
import { useLog } from '../../hooks/log';
import { type Href, type StrictObjHref, toAsUrl } from '../../utils/rewrites';
import { useLocale, type Language } from '../locale';

type RouterEvents = {|
  on: ((
    event: 'routeChangeError',
    callback: (err: Error, url: string) => void,
  ) => void) &
    ((
      event: 'routeChangeStart' | 'routeChangeComplete' | 'beforeHistoryChange',
      callback: (newPathAndQuery: string) => void,
    ) => void),
  off: ((
    event: 'routeChangeError',
    callback: (err: Error, url: string) => void,
  ) => void) &
    ((
      event: 'routeChangeStart' | 'routeChangeComplete' | 'beforeHistoryChange',
      callback: (newPathAndQuery: string) => void,
    ) => void),
|};

export type Router = {|
  ...StrictObjHref,
  asPath: string,
  replace: (
    href: Href,
    language?: Language | null,
    options?: {| shallow: boolean |},
  ) => void,
  push: (
    href: Href,
    language?: Language | null,
    options?: {| shallow: boolean |},
  ) => void,
  prefetch: (pathname: string) => void,
  back: () => void,
  events: RouterEvents,
  getUrl: (href: Href) => {| relative: string, absolute: string |},
|};

const RouterContext: React.Context<Router> = React.createContext(({}: any));

export const RouterConsumer = RouterContext.Consumer;

export const useRouter = (): Router => React.useContext(RouterContext);

type RouterEventHandler = (url: string) => void;
type RouterErrorEventHandler = (err: Error, url: string) => void;

const useLayoutEffect =
  typeof window !== 'undefined' ? React.useLayoutEffect : React.useEffect;

export const useRouterEvents = (handlers: {|
  onChangeStart?: RouterEventHandler,
  onChangeError?: RouterErrorEventHandler,
  onChangeComplete?: RouterEventHandler,
  onBeforeHistoryChange?: RouterEventHandler,
|}) => {
  const logger = useLog();
  const router = useRouter();
  const handlersRef = React.useRef(handlers);

  useLayoutEffect(() => {
    handlersRef.current = handlers;
  });

  React.useEffect(() => {
    const {
      onChangeStart,
      onChangeError,
      onChangeComplete,
      onBeforeHistoryChange,
    } = handlersRef.current;

    const handleStart = url => {
      if (onChangeStart == null) {
        return;
      }

      try {
        onChangeStart(url);
      } catch (e) {
        logger.error(e);
        throw e;
      }
    };

    const handleComplete = url => {
      if (onChangeComplete == null) {
        return;
      }

      try {
        onChangeComplete(url);
      } catch (e) {
        logger.error(e);
        throw e;
      }
    };

    const handleBeforeHistoryChange = url => {
      if (onBeforeHistoryChange == null) {
        return;
      }

      try {
        onBeforeHistoryChange(url);
      } catch (e) {
        logger.error(e);
        throw e;
      }
    };

    const handleError = (err, url) => {
      if (onChangeError == null) {
        return;
      }

      try {
        onChangeError(err, url);
      } catch (e) {
        logger.error(e);
        throw e;
      }
    };

    if (onChangeStart != null) {
      router.events.on('routeChangeStart', handleStart);
    }

    if (onChangeError != null) {
      router.events.on('routeChangeError', handleError);
    }

    if (onChangeComplete != null) {
      router.events.on('routeChangeComplete', handleComplete);
    }

    if (onBeforeHistoryChange != null) {
      router.events.on('beforeHistoryChange', handleBeforeHistoryChange);
    }

    return () => {
      if (onChangeStart != null) {
        router.events.off('routeChangeStart', handleStart);
      }

      if (onChangeError != null) {
        router.events.off('routeChangeError', handleError);
      }

      if (onChangeComplete != null) {
        router.events.off('routeChangeComplete', handleComplete);
      }

      if (onBeforeHistoryChange != null) {
        router.events.off('beforeHistoryChange', handleBeforeHistoryChange);
      }
    };
  }, [router, logger]);
};

export const useRouterChanging = (): null | {|
  match: {| pathname: string, query: { [string]: string, ... } |},
  rawUrl: string,
|} => {
  const { routes } = useUserData();
  const { language } = useLocale();
  const [changing, setChanging] = React.useState(null);
  const handlers = React.useMemo(
    () => ({
      onChangeStart: (rawUrl: string) => {
        setChanging({ rawUrl, match: toPageHref(routes, rawUrl, language) });
      },

      onChangeError: () => {
        setChanging(null);
      },
      onChangeComplete: () => {
        setChanging(null);
      },
    }),
    [language, routes],
  );

  useRouterEvents(handlers);

  return changing;
};

export const RouterProvider = ({
  children,
}: {|
  children: React.Node,
|}): React.Node => {
  const router = useNextRouter();
  const { language } = useLocale();
  const { routes, origin, initialReferrer } = useUserData();

  const backUrl = React.useRef(
    initialReferrer != null && initialReferrer.startsWith(origin)
      ? initialReferrer.slice(origin.length)
      : null,
  );

  React.useEffect(() => {
    const handler = url => {
      backUrl.current = url;
    };

    router.events.on('routeChangeComplete', handler);
    return () => {
      router.events.off('routeChangeComplete', handler);
    };
  }, [router, language, routes]);

  const customRouter = React.useMemo(() => {
    const { replace, push, ...routerClean } = router;

    return {
      ...routerClean,
      replace: (href, languageArg, options) => {
        const res = toAsUrl(routes, language, href, router);

        if (res) {
          router.replace(res.href, res.as, options);
        }
      },
      push: (href, languageArg, options) => {
        const res = toAsUrl(routes, language, href, router);

        if (res) {
          return router.push(res.href, res.as, options);
        }
      },

      getUrl: href => {
        const relative = toAsUrl(routes, language, href, router).as;
        const absolute = origin + relative;
        return { relative, absolute };
      },
    };
  }, [router, language, routes, origin]);

  return (
    <RouterContext.Provider value={customRouter}>
      {children}
    </RouterContext.Provider>
  );
};
