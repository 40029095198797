// @flow

// taken here https://github.com/ReactTraining/react-router

import { compile } from 'path-to-regexp';

const patternCache = {};
const cacheLimit = 10000;
let cacheCount = 0;

const compileGenerator = pattern => {
  const cacheKey = pattern;
  const cache = patternCache[cacheKey] || (patternCache[cacheKey] = {});

  if (cache[pattern]) {
    return cache[pattern];
  }

  const compiledGenerator = compile(pattern);

  if (cacheCount < cacheLimit) {
    cache[pattern] = compiledGenerator;
    cacheCount += 1;
  }

  return compiledGenerator;
};

/**
 * Public API for generating a URL pathname from a pattern and parameters.
 */
export const generatePath = (
  pattern: string = '/',
  params: { [key: string]: string, ... } = {},
): string => {
  if (pattern === '/') {
    return pattern;
  }
  const generator = compileGenerator(pattern);

  const cleanParams = {};
  for (const k in params) {
    if (params[k] !== '') {
      cleanParams[k] = params[k];
    }
  }

  return generator(cleanParams, { pretty: true });
};
