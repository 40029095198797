// @flow

// TODO: add ErrorsStore from crm to show errors
import {
  type IEnvironment,
  createOperationDescriptor,
  getRequest,
} from 'relay-runtime';
import { fetchQuery } from 'react-relay';
import type { ErrorItem } from '@realadvisor/error';
import type { UserData } from '../../server/UserData';
import { makeLogger, getCloudLogsTrace } from '../../log';
import { createRelayEnvironment } from './createRelayEnvironment';
import type { CacheStrategy } from './nextQuery';

const fetchRelayQuery = async (
  environment: any,
  asPath: string,
  {
    query,
    variables,
    cacheStrategy,
  }: {|
    query: any,
    variables: { ... },
    cacheStrategy: CacheStrategy,
  |},
  logger,
) => {
  let data = null;
  let operationFragment = null;

  const request = getRequest(query);
  const operationName = request.params.name;

  if (request.params.operationKind !== 'query') {
    throw Error('Expected query operation');
  }

  const operation = createOperationDescriptor(request, variables);

  try {
    if (
      (cacheStrategy === 'cache-first' || cacheStrategy === 'cache-only') &&
      environment.check(operation) === 'available'
    ) {
      // get from cache
      data = environment.lookup(operation.fragment, operation).data;
      operationFragment = operation.fragment;

      if (cacheStrategy === 'cache-first') {
        fetchQuery(environment, query, variables).subscribe({});
      }
    } else {
      if (typeof window !== 'undefined') {
        environment.retain(operation);
      }

      data = await fetchQuery(environment, query, variables).toPromise();

      if (typeof window !== 'undefined') {
        environment.retain(operation);
      }
    }
  } catch (e) {
    logger.error(
      e instanceof Error
        ? 'fetchQuery'
        : new Error(e.errors?.[0]?.message ?? 'fetchQuery'),
      e,
    );

    const errors = (e.errors ?? [{ message: e.message }]).map(e => ({
      ...e,
      operationName: e.operationName ?? operationName,
    }));

    operationName;
    return {
      data: e.data ?? null,
      errors,
      operationFragment,
      operationName,
      operation,
    };
  }

  return {
    data,
    errors: null,
    operationFragment,
    operationName,
    operation,
  };
};

export type RelayProps = {|
  cacheQuery: {| variables: { ... }, cacheStrategy: CacheStrategy |},
  errors: null | $ReadOnlyArray<ErrorItem>,
  records: any | null,
|};

type QueryInfo = {|
  query: any,
  variables: { ... },
  cacheStrategy: CacheStrategy,
|};

export const handleGetInitialProps = async ({
  queryInfo,
  asPath,
  userData,
  request,
}: {|
  queryInfo: QueryInfo,
  asPath: string,
  userData: UserData,
  request?: http$IncomingMessage<>,
|}): Promise<{|
  relayProps: RelayProps,
  validationData: null | {
    data: any,
    errors: null | $ReadOnlyArray<ErrorItem>,
    operation: any,
    operationFragment: any | null,
    operationName: any,
    ...
  },
|}> => {
  const logger = makeLogger(request && getCloudLogsTrace(request));

  let records = null;
  let environment = null;
  if (typeof window === 'undefined') {
    environment = createRelayEnvironment({ userData, request });
  } else {
    if (window.environment) {
      environment = window.environment;
    } else {
      environment = window.environment = createRelayEnvironment({
        userData,
      });
    }
  }

  const res = await fetchRelayQuery(environment, asPath, queryInfo, logger);

  if (typeof window === 'undefined') {
    records = environment.getStore().getSource().toJSON();
  }

  const cacheQuery = {
    variables: queryInfo.variables,
    cacheStrategy: queryInfo.cacheStrategy,
  };

  return {
    relayProps: {
      cacheQuery,
      records,
      errors: res.errors,
    },
    // Used for nextQuery validate, DO NOT RETURN IT FROM getInitialProps as its huge
    validationData: res,
  };
};

export const handleRender = (
  queryInfo: null | QueryInfo,
  relayProps: null | RelayProps,
  userData: UserData,
): {|
  environment: IEnvironment,
  result: null | {|
    data: any,
    operationName: string,
    variables: { ... },
  |},
  variables?: any | null,
|} => {
  let environment = typeof window !== 'undefined' ? window.environment : null;

  // records not null on server render, and on first client render
  if (environment == null) {
    environment = createRelayEnvironment({
      records: relayProps?.records ?? null,
      userData,
    });

    if (typeof window !== 'undefined') {
      // first app render on a client so getInitialProps wasn't called
      window.environment = environment;
    }
  }

  if (relayProps == null || queryInfo == null) {
    return {
      environment,
      result: null,
    };
  }

  // to support cache first operation without subscriptions
  // if data was from cache, then operationFragment will be provided
  // after refetch shallow refresh will call current ender
  // so we could took updated data again
  const cachedQuery = relayProps.cacheQuery;

  const query = queryInfo.query;
  const queryConcreteRequest = getRequest(query);
  const operationName = queryConcreteRequest.params.name;
  const requestIdentifier = createOperationDescriptor(
    queryConcreteRequest,
    cachedQuery.variables,
  );

  const pageData = environment?.lookup(requestIdentifier.fragment) ?? null;

  if (typeof window !== 'undefined') {
    environment?.retain(requestIdentifier);
  }

  const result = {
    operationName,
    variables: cachedQuery.variables,
    data: pageData?.data ?? null,
  };

  return {
    result,
    environment,
  };
};
