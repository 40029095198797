// @flow

import { format, parse } from 'url';
import * as React from 'react';
import { RelayEnvironmentProvider, ReactRelayContext } from 'react-relay';
import App from 'next/app';
import Head from 'next/head';
import { useScript } from '@realadvisor/hooks';
import { GoogleKeyProvider } from '@realadvisor/google-maps';
import { ImageProvider } from '@realadvisor/image';
import { makeLogger, getCloudLogsTrace } from '../log';
import { ThemeProvider, theme, GlobalStyle } from '../theme';
import { UserDataProvider } from '../hooks/user-data.js';
import { LocaleProvider } from '../controls/locale/index.js';
import { AnalyticsProvider } from '../controls/analytics';
import { ViewportTypeProvider } from '../hooks/viewport-type';
import {
  handleGetInitialProps,
  handleRender,
  type RelayProps,
} from '../controls/relay/nextRelayHandlers';
import { RouterProvider, useRouter } from '../controls/router';
import type { CacheStrategy } from '../controls/relay';
import type { UserData } from '../server/UserData';
import { useNoSSR } from '../hooks/nossr';
import { ErrorSubscriber } from '../shared/error-subscriber';
import { SeoUserInfoInit } from '../shared/seo-user-info';
import { ErrorComponent, ErrorPage } from './_error.page';

type Context = {
  asPath: string,
  query: {
    version?: string,
    editor?: string,
    redirect?: string,
    ...
  },
  req?: http$IncomingMessage<> & {
    userData: UserData,
    isNextStaticUrl?: boolean,
    ...
  },
  res?: http$ServerResponse,
  ...
};

const ShallowLanguageRedirect = ({ language }) => {
  const { pathname, query, replace } = useRouter();

  const runOnce = React.useRef(false);
  React.useEffect(() => {
    if (runOnce.current === false) {
      replace(
        {
          pathname,
          query,
        },
        language,
        { shallow: true },
      );
    }
    runOnce.current = true;
  }, [language, pathname, query, replace]);

  return null;
};

const NoSSRCache = () => {
  // we call it for caching purposes
  useNoSSR();

  return null;
};

const PrismicToolbar = () => {
  // Should be everywhere, even on 404 page
  // https://prismic.io/docs/rest-api/beyond-the-api/the-preview-feature
  React.useEffect(() => {
    window.prismic = {
      endpoint: 'https://realadvisor.cdn.prismic.io/api/v2',
    };
  }, []);

  useScript('//static.cdn.prismic.io/prismic.min.js');

  return null;
};

export default class RealadvisorApp extends App {
  static async getInitialProps({
    Component,
    ctx,
  }: {
    Component: {
      getInitialProps: (Context, UserData) => { ... },
      getRelay: (
        Context,
        UserData,
      ) => {|
        query: any,
        variables: { ... },
        cacheStrategy: CacheStrategy,
        validate?: (data: any) =>
          | null
          | {|
              type: 'redirect',
              statusCode: 301 | 308 | 302 | 303 | 307,
              location: string,
              page?: string,
            |}
          | {| type: 'error', statusCode: number |},
      |},
      ...
    },
    ctx: Context,
    ...
  }): any {
    let pageProps = {};

    // TODO: pass initial via router.query then on browser level save in window
    // this will allow to not use next internals.

    // here is the usual nextjs trick to read server provided data
    // read from req if on server, or read current method result serialized on server
    // see we return locale at the end of this function
    const userData: ?UserData = ctx.req
      ? ctx.req.userData
      : (window.__NEXT_DATA__.props.userData: UserData);

    const logger = makeLogger(
      ctx.req != null
        ? getCloudLogsTrace(ctx.req)
        : userData?.ssrCloudLogsTrace,
    );

    // It's most likely that Next is rendering the 404 page for a static URL,
    // or some HMR problem
    if (userData == null) {
      // ... but if it's not a static URL, then we have some other issue
      if (ctx.req != null && ctx.req.isNextStaticUrl !== true) {
        const { url } = ctx.req;
        logger.error(
          new Error(`userData not found while handling non system URL: ${url}`),
          url,
        );
      }

      return {
        userDataNotFound: true,
        pageProps: null,
        userData: null,
        relayProps: null,
      };
    }

    if (userData.errors != null) {
      // don't fetch if already errors, prevents infinite error
      // in case of api unavailable on same host
      return {
        pageProps: null,
        userData,
        relayProps: null,
      };
    }

    // Usual processing
    if (Component.getInitialProps) {
      pageProps = await Component.getInitialProps(ctx, userData);
    }

    const pageQuery =
      Component.getRelay == null ? null : Component.getRelay(ctx, userData);

    if (pageQuery == null) {
      return {
        pageProps,
        userData,
        relayProps: null,
      };
    }

    const { relayProps, validationData } = await handleGetInitialProps({
      queryInfo: {
        query: pageQuery.query,
        variables: pageQuery.variables,
        cacheStrategy: pageQuery.cacheStrategy,
      },
      asPath: ctx.asPath,
      userData,
      request: ctx.req,
    });

    const result = {
      pageProps,
      userData,
      relayProps,
    };

    if (pageQuery.validate != null && validationData?.data != null) {
      const validation = pageQuery.validate(validationData.data);

      if (validation == null) {
        return result;
      }

      logger.info('Page validation failed', validation);

      if (validation.type === 'redirect') {
        const { req, res } = ctx;

        // we want to make sure there're no infinite redirects
        const MAX_REDIRECT_COUNT = 5;
        const parseRedirectCount = url => {
          const { query } = parse(url, true);
          const count = Number.parseInt(query.redirect, 10);
          return Number.isNaN(count) ? 0 : count;
        };
        const finalLocation = currentRedirectCount => {
          const redirectHref = parse(validation.location, true);
          return format({
            pathname: redirectHref.pathname,
            query: {
              ...redirectHref.query,
              redirect: currentRedirectCount + 1,
            },
          });
        };

        // client-side navigation (we're in a browser)
        // redirect in browser is very unlikely to happen, but just in case let's implement it
        if (res == null || req == null) {
          // Should never happen
          if (typeof window === 'undefined') {
            return {
              ...result,
              pageDataValidationError: { type: 'error', statusCode: 500 },
            };
          }

          const redirectCount = parseRedirectCount(window.location.href);
          if (redirectCount > MAX_REDIRECT_COUNT) {
            logger.error(
              new Error(`Redirect loop error "${window.location.href}"`),
            );
            return {
              ...result,
              pageDataValidationError: { type: 'error', statusCode: 404 },
            };
          }

          window.location.replace(finalLocation(redirectCount));

          // To keep showing user the current page
          // while browser is navigating to `finalLocation`
          // we return a promise that never resolve
          return new Promise(() => {});
        }

        const redirectCount = parseRedirectCount(req.url);
        if (redirectCount > MAX_REDIRECT_COUNT) {
          logger.error(new Error(`Redirect loop error "${req.url}"`));
          res.statusCode = 404;
          return {
            ...result,
            pageDataValidationError: { type: 'error', statusCode: 404 },
          };
        }

        if (validation.page === 'listings') {
          res.writeHead(validation.statusCode, {
            Location: validation.location,
          });
          return res.end();
        }

        res.writeHead(validation.statusCode, {
          Location: finalLocation(redirectCount),
        });
        res.end();

        // Doesn't matter what we return here
        // Next does not call render() if `res` is ended
        return null;
      }

      if (ctx.res != null) {
        ctx.res.statusCode = validation.statusCode;
      }

      return {
        ...result,
        pageDataValidationError: validation,
      };
    }

    return result;
  }

  componentDidMount() {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles && jssStyles.parentNode) {
      jssStyles.parentNode.removeChild(jssStyles);
    }
  }

  render(): React.Node {
    const { Component, pageProps, router } = this.props;

    const relayProps: null | RelayProps = this.props.relayProps;
    const pageDataValidationError: null | {| statusCode: number |} =
      this.props.pageDataValidationError;

    const userData: ?UserData = this.props.userData;

    const logger = makeLogger(userData?.ssrCloudLogsTrace);

    const isHMR = pageProps === undefined;
    if (isHMR) {
      return null;
    }

    if (this.props.userDataNotFound === true) {
      return <ErrorComponent debug={false} code={404} errors={[]} />;
    }

    // Can occur on hmr
    if (userData == null) {
      logger.error(new Error('userData is null'));
      return <ErrorComponent debug={true} code={404} errors={[]} />;
    }

    const { debug } = userData;

    // In case of userData errors we even don't try to show Layout,
    // As this error usually mean that something seriously broken
    if (userData.errors != null) {
      logger.error(new Error('userData.errors'), userData.errors);
      const code = userData.errors?.[0].code ?? 1301;
      return (
        <ErrorComponent
          debug={debug}
          code={code}
          errors={userData.errors ?? []}
        />
      );
    }

    // Passing queries via getInitialProps causes data passed to be enormously huge
    // so we are restoring queries on the fly here in a same manner as at getInitialProps
    const pageQuery =
      Component.getRelay == null ? null : Component.getRelay(router, userData);

    const { environment, result } = handleRender(
      pageQuery,
      relayProps,
      userData,
    );

    const pageData = result;

    const { language, i18nOptions, shallowLanguageRedirect, publicKeys } =
      (userData: UserData);

    const statusCode =
      pageProps?.statusCode ?? pageDataValidationError?.statusCode ?? null;
    const errors = relayProps?.errors ?? null;

    return (
      <>
        <Head>
          <meta
            name="viewport"
            content="user-scalable=0, initial-scale=1, minimum-scale=1, width=device-width, height=device-height"
          />
          <meta charSet="UTF-8" />
          <meta name="msapplication-TileColor" content="#ffffff" />
          <meta
            name="msapplication-config"
            content="/static/icons/browserconfig.xml"
          />
          <meta name="theme-color" content="#ffffff" />
          <meta name="apple-itunes-app" content="app-id=6504883593" />
          <meta name="apple-mobile-web-app-title" content="RealAdvisor" />
          <meta name="apple-mobile-web-app-capable" content="yes" />
        </Head>
        <GlobalStyle />
        <RelayEnvironmentProvider environment={environment}>
          <AnalyticsProvider
            googleTagManagerId={publicKeys.GOOGLE_TAG_MANAGER_ID}
          >
            {/* should be bbefore locale provider */}
            <UserDataProvider value={userData}>
              <SeoUserInfoInit />
              {/* should be before all themes */}
              <LocaleProvider
                language={language}
                options={i18nOptions}
                locize={'locize' in router.query}
              >
                <ThemeProvider value={theme}>
                  <ImageProvider ssr={true}>
                    <ViewportTypeProvider>
                      <GoogleKeyProvider
                        googleKey={publicKeys.GOOGLE_MAPS_TOKEN}
                      >
                        <RouterProvider>
                          <ReactRelayContext.Provider
                            value={
                              ({
                                environment,
                                // query renderer do not pass variables anymore
                                // TODO remove while refactoring
                                variables: pageData && pageData.variables,
                              }: any)
                            }
                          >
                            {userData.isPreview && <PrismicToolbar />}
                            {statusCode != null || errors != null ? (
                              <ErrorPage
                                debug={debug}
                                code={statusCode}
                                errors={errors ?? []}
                              />
                            ) : (
                              <>
                                {shallowLanguageRedirect && (
                                  <ShallowLanguageRedirect
                                    language={language}
                                  />
                                )}

                                <ErrorSubscriber debug={debug} />

                                <NoSSRCache />
                                <Component
                                  {...pageProps}
                                  data={pageData && pageData.data}
                                  variables={pageData && pageData.variables}
                                  operationName={
                                    pageData && pageData.operationName
                                  }
                                  router={router}
                                  userData={userData}
                                />
                              </>
                            )}
                          </ReactRelayContext.Provider>
                        </RouterProvider>
                      </GoogleKeyProvider>
                    </ViewportTypeProvider>
                  </ImageProvider>
                </ThemeProvider>
              </LocaleProvider>
            </UserDataProvider>
          </AnalyticsProvider>
        </RelayEnvironmentProvider>
      </>
    );
  }
}
