// @flow

import * as React from 'react';
import dynamic from 'next/dynamic';
import { useConstant } from '@realadvisor/hooks';
import type { Translate, Interpolate } from '@realadvisor/translator/engine';
import {
  makeTranslatorStore,
  getTranslatorResources,
  addTranslatorResources,
  clearTranslatorResources,
  translate,
  interpolate,
} from '@realadvisor/translator/engine';
import type { DateLocale, Language } from '../../language.js';
import {
  getDateLocale,
  getLocale,
  list,
  getLanguageListForCountry,
} from '../../language.js';
import type { I18nOptions, CountryCode } from '../../server/UserData';
import { useUserData } from '../../hooks/user-data.js';
import { numberShortener } from '../../utils/format.js';
import { useLog } from '../../hooks/log';

const TranslatorDevtool = dynamic(() =>
  import('@realadvisor/translator/devtool').then(m => m.TranslatorDevtool),
);

type NumberLocaleFormat = (
  number: number,
  options: Intl$NumberFormatOptions,
  customLocale?: string,
) => string;

type NumberLocale = {|
  format: NumberLocaleFormat,
  formatShort: NumberLocaleFormat,
|};

export type TranslateFn = Translate;

export type I18Locale = {|
  t: Translate,
  interpolate: Interpolate,
  // all locales has the same type which can be extracted
  // with typeof from any of them
  dateLocale: DateLocale,
  numberLocale: NumberLocale,
  currency: 'CHF' | 'EUR' | 'GBP',
  // to create language dependent external links
  language: Language,
  languages: $ReadOnlyArray<Language>,
|};

const LocaleContext = React.createContext<I18Locale>({
  t: text => text,
  interpolate: text => text,
  dateLocale: (({}: any): DateLocale),
  numberLocale: ({ format: () => '', formatShort: () => '' }: NumberLocale),
  currency: 'CHF',
  language: 'en',
  languages: [],
});

export const useLocale = (): I18Locale => React.useContext(LocaleContext);

const fetchTranslatorData = (signal, userData, languages) => {
  const { serverApiOrigin } = userData;
  const uri = `${serverApiOrigin}/api/translator/list`;
  const request = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    signal,
    body: JSON.stringify({ project: 'agg', languages }),
  };
  return fetch(uri, request).then(response => {
    if (response.ok === false) {
      throw Error(response.statusText);
    }
    return response.json();
  });
};

const updateTranslatorData = (signal, userData, diff, updatedBy) => {
  const { serverApiOrigin } = userData;
  const uri = `${serverApiOrigin}/api/translator/update`;
  const request = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    signal,
    body: JSON.stringify({ updatedBy, project: 'agg', diff }),
  };
  return fetch(uri, request).then(response => {
    if (response.ok === false) {
      throw Error(response.statusText);
    }
  });
};

function createNewCachedItem(locale, currency, options, logger) {
  let item;
  try {
    item = new Intl.NumberFormat(locale, options);
  } catch (e) {
    logger.error(e);
    // bad currency format
    item = new Intl.NumberFormat(locale, {
      ...options,
      currency,
    });
  }
  return item;
}

export const getCurrencyByCountryCode = (
  countryCode: CountryCode | string,
): 'CHF' | 'GBP' | 'EUR' => {
  switch (countryCode) {
    case 'CH':
      return 'CHF';
    case 'GB':
      return 'GBP';
    default:
      return 'EUR';
  }
};

export const LocaleProvider = (props: {|
  language: Language,
  options: I18nOptions,
  locize: boolean,
  children: React.Node,
|}): React.Node => {
  const userData = useUserData();
  const { isProduction, countryDomain } = userData;
  const intlFormatCache = useConstant(() => new Map());
  const [store, setStore] = React.useState(() =>
    makeTranslatorStore({
      currentLanguage: props.options.currentLanguage,
      fallbackLanguage: props.options.fallbackLanguage,
      devtool: isProduction === false && props.locize,
      resources: props.options.resources,
      locale: getLocale(countryDomain, props.language),
    }),
  );
  React.useEffect(() => {
    if (store.devtool) {
      const controller = new AbortController();
      fetchTranslatorData(controller.signal, userData, list)
        .then(newValue => {
          addTranslatorResources(store.index, newValue);
          // trigger local update
          setStore(store => ({ ...store }));
        })
        .catch(error => {
          if (error.name !== 'AbortError') {
            throw error;
          }
        });
      return () => controller.abort();
    }
  }, [store.devtool, store.index, userData]);

  const logger = useLog();

  const locale = React.useMemo(() => {
    let currency;
    switch (countryDomain) {
      case 'ch':
        currency = 'CHF';
        break;
      case 'fr':
      case 'de':
      case 'it':
      case 'es':
        currency = 'EUR';
        break;
      case 'gb':
        currency = 'GBP';
        break;
      default:
        throw Error(`Unknown currency for ${countryDomain}`);
    }

    return {
      t: (key, options) => translate(store, key, options),
      interpolate,
      currency,
      language: props.language,
      languages: getLanguageListForCountry(countryDomain),
      numberLocale: {
        format: (
          number: number,
          options?: Intl$NumberFormatOptions,
          customLocale?: string,
        ) => {
          const key = JSON.stringify(options ?? {});
          let cachedItem = intlFormatCache.get(key);
          if (cachedItem == null) {
            const locale =
              customLocale ?? getLocale(countryDomain, props.language);
            cachedItem = createNewCachedItem(locale, currency, options, logger);
            intlFormatCache.set(key, cachedItem);
          }
          const res = cachedItem.format(number);

          return (
            res
              // macOS cuts this some kind of whitespaces for some fonts when they are bold
              // so we replace all spaces by &nbsp; here
              .replace(/\s+/g, '\u00a0')
              // not sure why we need it, found in Ivan's code
              .replace(/'/g, '’')
          );
        },
        // delete this and replace all usages by format() and add notation compact
        // when the lower version of Safari we support will support notation
        formatShort: (
          number: number,
          options?: Intl$NumberFormatOptions,
          customLocale?: string,
        ) => {
          const optionsCompact = { ...options, notation: 'compact' };
          const key = JSON.stringify(optionsCompact ?? {});
          let cachedItem = intlFormatCache.get(key);
          if (cachedItem == null) {
            const locale =
              customLocale ?? getLocale(countryDomain, props.language);
            cachedItem = createNewCachedItem(
              locale,
              currency,
              optionsCompact,
              logger,
            );
            intlFormatCache.set(key, cachedItem);
          }
          if ('notion' in cachedItem.resolvedOptions()) {
            return (
              cachedItem
                .format(number)
                // macOS cuts this some kind of whitespaces for some fonts when they are bold
                // so we replace all spaces by &nbsp; here
                .replace(/\s+/g, '\u00a0')
                // not sure why we need it, found in Ivan's code
                .replace(/'/g, '’')
            );
          } else {
            const locale =
              customLocale ?? getLocale(countryDomain, props.language);
            return numberShortener(number, null, options, locale);
          }
        },
      },
      dateLocale: getDateLocale(props.language),
    };
  }, [store, countryDomain, intlFormatCache, props.language, logger]);

  return (
    <>
      {store.devtool && (
        <TranslatorDevtool
          project="agg"
          store={store}
          onStoreInvalidate={() => {
            // trigger local update
            setStore(store => ({ ...store }));
          }}
          onPublish={updatedBy => {
            const controller = new AbortController();
            const newDiff = getTranslatorResources(store.draft);
            updateTranslatorData(
              controller.signal,
              userData,
              newDiff,
              updatedBy,
            )
              .then(() => {
                addTranslatorResources(store.index, newDiff);
                clearTranslatorResources(store.draft);
                // trigger local update
                setStore(store => ({ ...store }));
              })
              .catch(error => {
                if (error.name !== 'AbortError') {
                  throw error;
                }
              });
          }}
        />
      )}
      <LocaleContext.Provider value={locale}>
        {props.children}
      </LocaleContext.Provider>
    </>
  );
};
