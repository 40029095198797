// @flow

import * as React from 'react';
import { useLog } from './log';
import { useNoSSR } from './nossr';
import { useUserData } from './user-data';

// return localStorage or sessionStorage (handles safari private mode)
export const getStorage = (): Storage => {
  try {
    const key = '__test__';
    window.localStorage.setItem(key, key);
    window.localStorage.removeItem(key);
    return window.localStorage;
  } catch (e) {
    return window.sessionStorage;
  }
};

export const useLocalStorage = <T>(
  key: string,
  initialValue: T,
): [T, (T) => void] => {
  const versionKey = `${key}__VERSION`;
  const logger = useLog();
  const noSSR = useNoSSR();
  const storage = noSSR ? getStorage() : null;
  const { version } = useUserData();
  const [value, setValue] = React.useState<T>(
    noSSR
      ? () => {
          try {
            // compare stored value version with the current app version, and invalidate this if they are not equal
            const storedVersion = storage?.getItem(versionKey);
            if (storedVersion !== version) {
              storage?.removeItem(key);
              return initialValue;
            }

            const item = storage?.getItem(key);
            return item != null ? JSON.parse(item) : initialValue;
          } catch (error) {
            logger.error(error);
            return initialValue;
          }
        }
      : initialValue,
  );

  if (!noSSR) {
    return [value, setValue];
  }

  return [
    value,
    newValue => {
      storage?.setItem(key, JSON.stringify(newValue) ?? '');
      storage?.setItem(versionKey, version);

      setValue(newValue);
    },
  ];
};
