/**
 * @flow
 */

/* eslint-disable */

'use strict';


/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UserLanguage = "de" | "en" | "es" | "fr" | "it";
export type analyticsMeQueryVariables = {||};
export type analyticsMeQueryResponse = {|
  +me: ?{|
    +id: string,
    +firstName: ?string,
    +lastName: ?string,
    +primaryEmail: ?{|
      +email: string
    |},
    +primaryPhoneNumber: ?{|
      +number: string
    |},
    +language: ?UserLanguage,
  |}
|};
export type analyticsMeQuery = {|
  variables: analyticsMeQueryVariables,
  response: analyticsMeQueryResponse,
|};
*/


/*
query analyticsMeQuery {
  me {
    id
    firstName
    lastName
    primaryEmail {
      email
      id
    }
    primaryPhoneNumber {
      number
      id
    }
    language
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "number",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "language",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "analyticsMeQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Email",
            "kind": "LinkedField",
            "name": "primaryEmail",
            "plural": false,
            "selections": [
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PhoneNumber",
            "kind": "LinkedField",
            "name": "primaryPhoneNumber",
            "plural": false,
            "selections": [
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "analyticsMeQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Email",
            "kind": "LinkedField",
            "name": "primaryEmail",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v0/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PhoneNumber",
            "kind": "LinkedField",
            "name": "primaryPhoneNumber",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v0/*: any*/)
            ],
            "storageKey": null
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "00d0e6c7b257041cd041856ea9ad8116",
    "id": null,
    "metadata": {},
    "name": "analyticsMeQuery",
    "operationKind": "query",
    "text": "query analyticsMeQuery {\n  me {\n    id\n    firstName\n    lastName\n    primaryEmail {\n      email\n      id\n    }\n    primaryPhoneNumber {\n      number\n      id\n    }\n    language\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '69c31b58acbcd6334fe033480f92c9d9';

export default node;
