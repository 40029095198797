// @flow

import type { Logger } from '../log';

export const startTimeLog = (label: string, logger: Logger): (() => number) => {
  // skip measurements on a client
  if (typeof window !== 'undefined') {
    return () => 0;
  }

  const hrstart = process.hrtime();

  return () => {
    const hrend = process.hrtime(hrstart);
    const ms = hrend[0] * 1000 + hrend[1] / 1000000;
    logger.info(label, ms);
    return ms;
  };
};
