// @flow

import * as React from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { createNanoEvents } from 'nanoevents';
import { shallowEqualObjects } from 'shallow-equal';

const throwError = () => {
  throw new Error('Deep State provider not found');
};

const ssrReadyLayoutEffect =
  typeof window !== 'undefined' ? React.useLayoutEffect : React.useEffect;

type Store<State> = {|
  set: ((State) => State) => void,
  on: (() => void) => void => void,
  get: () => State,
|};

type ProviderProps<State> =
  | {|
      children: React.Node,
      store: Store<State>,
    |}
  | {|
      children: React.Node,
      initial: State,
    |};

export type DeepState<State> = {|
  Provider: React.AbstractComponent<ProviderProps<State>>,
  useSelector: <T>(mapState: (State) => T) => T,
  useReduce: () => ((State) => State) => void,
  useStore: (initialValue: State | (() => State)) => Store<State>,
|};

export const createDeepState = <State>(): DeepState<State> => {
  const Context = React.createContext<Store<State>>({
    set: throwError,
    on: throwError,
    get: throwError,
  });

  const useStore = (initialStateOrFn: State | (() => State)) => {
    const [initialState] = React.useState(initialStateOrFn);
    const state = React.useRef(initialState);

    const [store] = React.useState(() => {
      const emitter = createNanoEvents<{| '#': [] |}>();
      let updateStarted = false;

      return {
        set: (reduce: State => State) => {
          const prevState = state.current;
          state.current = reduce(state.current);
          if (prevState === state.current) {
            return;
          }

          if (!updateStarted) {
            updateStarted = true;
            unstable_batchedUpdates(() => {
              updateStarted = false;
              emitter.emit('#');
            });
          }
        },

        on: fn => {
          return emitter.on('#', fn);
        },

        get: () => state.current,
      };
    });

    return store;
  };

  const Provider = (props: ProviderProps<State>) => {
    // In some rare cases direct access to store object is needed
    if (props.store !== undefined) {
      return (
        <Context.Provider value={props.store}>
          {props.children}
        </Context.Provider>
      );
    }

    if (props.initial !== undefined) {
      // eslint-disable-next-line
      const store = useStore(props.initial);
      return (
        <Context.Provider value={store}>{props.children}</Context.Provider>
      );
    }

    throw new Error('impossible path');
  };

  const useSelector = <T>(mapState: State => T): T => {
    // https://github.com/facebookincubator/redux-react-hook
    const ctx = React.useContext(Context);

    const value = mapState(ctx.get());

    const [, refresh] = React.useReducer(s => s + 1, 0);

    const refs = React.useRef({
      mapState,
      value,
    });

    ssrReadyLayoutEffect(() => {
      refs.current.mapState = mapState;
      refs.current.value = value;
    }, [mapState, value]);

    React.useEffect(() => {
      // https://github.com/facebook/react/issues/14369#issuecomment-468270539
      let didUnmount = false;

      const handleUpdates = () => {
        if (didUnmount) {
          return;
        }

        const newValue = refs.current.mapState(ctx.get());

        if (!shallowEqualObjects(newValue, refs.current.value)) {
          refresh();
        }
      };

      // in case of ctx changed or first update was
      handleUpdates();

      const unsubscribe = ctx.on(handleUpdates);

      return () => {
        didUnmount = true;
        unsubscribe();
      };
    }, [ctx]);

    return value;
  };

  const useReduce = () => {
    const ctx = React.useContext(Context);
    return ctx.set;
  };

  return {
    Provider,
    useSelector,
    useReduce,
    useStore,
  };
};
