// @flow

export const getTrace = (
  req: http$IncomingMessage<any>,
  project: ?string = process.env.GCP_PROJECT,
): ?string => {
  const traceContext = req.headers['x-cloud-trace-context'];
  if (project == null || traceContext == null) {
    return null;
  }
  return `projects/${project}/traces/${traceContext.split('/')[0]}`;
};
