// @flow

// taken here https://github.com/voltace/browser-cookies/blob/master/src/browser-cookies.js
// without most options

export const set = (
  name: string,
  value: string,
  options?: {| expires?: number |},
) => {
  // used from esm so no ?. or ?? available
  const expires =
    options != null && options.expires != null ? options.expires : null;

  const expDate =
    expires != null
      ? new Date(new Date().getTime() + expires * 60 * 60 * 24 * 1000)
      : null;

  const cleanName = name
    .replace(/[^+#$&^`|]/g, encodeURIComponent) // Encode cookie name
    .replace('(', '%28')
    .replace(')', '%29');

  const cleanValue = value.replace(/[^+#$&/:<-[\]-}]/g, encodeURIComponent);

  document.cookie = `${cleanName}=${cleanValue};path=/;${
    expDate != null ? `;expires=${expDate.toUTCString()}` : ''
  }`;
};

export const get = (name: string, cookieString?: string): null | string => {
  const cookies =
    cookieString != null ? cookieString.split(';') : document.cookie.split(';');

  while (cookies.length) {
    var cookie = cookies.pop();
    var separatorIndex = cookie.indexOf('=');
    // IE<11 emits the equal sign when the cookie value is empty
    separatorIndex = separatorIndex < 0 ? cookie.length : separatorIndex;

    var cookieName = decodeURIComponent(
      cookie.slice(0, separatorIndex).replace(/^\s+/, ''),
    );

    if (cookieName === name) {
      return decodeURIComponent(cookie.slice(separatorIndex + 1));
    }
  }

  return null;
};

export const erase = (name: string) => {
  set(name, '', {
    expires: -1,
  });
};
