// @flow

import * as React from 'react';
import dynamic from 'next/dynamic';
import type { ErrorProps } from '../shared/error';

export const ErrorPage: React.AbstractComponent<ErrorProps, mixed> = dynamic(
  () => import('../shared/error').then(m => m.ErrorPage),
);

const DynamicError = dynamic(() =>
  import('../shared/error').then(m => m.ErrorComponent),
);

export const ErrorComponent = (props: ErrorProps): React.Node => {
  return <DynamicError {...props} />;
};

ErrorComponent.getInitialProps = ({ res, err }) => {
  const statusCode = res ? res.statusCode : err ? err.statusCode : null;
  return { statusCode };
};

export default ErrorComponent;
