// @flow

export const translations = {
  en: {
    map: 'map',
    buy: 'buy',
    rent: 'rent',
    page: 'page-',
    house: 'house',
    apartment: 'apartment',
    houseApartment: 'house-apartment',
    commercial: 'commercial',
    building: 'building',
    parking: 'parking',
    room: 'room-flatshare',
    plot: 'plot',
    hospitality: 'hospitality',
    otherType: 'others',
    rooms: (from: number, to: ?number): string => {
      if (to == null) {
        return from === 1 ? `over-${from}-room` : `over-${from}-rooms`;
      }
      if (from === to) {
        return from === 1 ? `${from}-room` : `${from}-rooms`;
      }
      return `${from}-to-${to}-rooms`;
    },
  },
  fr: {
    map: 'carte',
    buy: 'acheter',
    rent: 'louer',
    page: 'page-',
    house: 'maison',
    apartment: 'appartement',
    houseApartment: 'maison-appartement',
    commercial: 'commercial',
    building: 'immeuble',
    parking: 'parking',
    room: 'chambre-colocation',
    plot: 'terrain',
    hospitality: 'hotellerie',
    otherType: 'autres',
    rooms: (from: number, to: ?number): string => {
      if (to == null) {
        return from === 1 ? `plus-de-${from}-piece` : `plus-de-${from}-pieces`;
      }
      if (from === to) {
        return from === 1 ? `${from}-piece` : `${from}-pieces`;
      }
      return `${from}-a-${to}-pieces`;
    },
  },
  de: {
    map: 'karte',
    buy: 'kaufen',
    rent: 'mieten',
    page: 'seite-',
    house: 'haus',
    apartment: 'wohnung',
    houseApartment: 'haus-wohnung',
    commercial: 'gewerbe',
    building: 'gebaude',
    parking: 'parkplatz',
    room: 'wg-zimmer',
    plot: 'grundstuck',
    hospitality: 'gastfreundschaft',
    otherType: 'andere',
    rooms: (from: number, to: ?number): string => {
      if (to == null) {
        return from === 1 ? `uber-${from}-zimmer` : `uber-${from}-zimmer`;
      }
      if (from === to) {
        return from === 1 ? `${from}-zimmer` : `${from}-zimmer`;
      }
      return `${from}-bis-${to}-zimmer`;
    },
  },
  it: {
    map: 'mappa',
    buy: 'comprare',
    rent: 'affitto',
    page: 'pagina-',
    house: 'casa',
    apartment: 'appartamento',
    houseApartment: 'casa-appartamento',
    commercial: 'commerciale',
    building: 'edificio',
    parking: 'parcheggio',
    room: 'camera-flatshare',
    plot: 'trama',
    hospitality: 'ospitalita',
    otherType: 'altri',
    rooms: (from: number, to: ?number): string => {
      if (to == null) {
        return from === 1 ? `circa-${from}-crema` : `circa-${from}-camere`;
      }
      if (from === to) {
        return from === 1 ? `${from}-crema` : `${from}-camere`;
      }
      return `${from}-a-${to}-camere`;
    },
  },
  es: {
    map: 'mapa',
    buy: 'comprar',
    rent: 'renta',
    page: 'página-',
    house: 'casa',
    apartment: 'apartamento',
    houseApartment: 'casa-apartamento',
    commercial: 'comercial',
    building: 'edificio',
    parking: 'estacionamiento',
    room: 'piso-compartido',
    plot: 'gráfico',
    hospitality: 'hospitalidad',
    otherType: 'otros',
    rooms: (from: number, to: ?number): string => {
      if (to == null) {
        return from === 1
          ? `encima-${from}-habitación`
          : `encima-${from}-habitaciones`;
      }
      if (from === to) {
        return from === 1 ? `${from}-habitación` : `${from}-habitaciones`;
      }
      return `${from}-a-${to}-habitaciones`;
    },
  },
};
