// @flow

import * as React from 'react';
import NextRouter from 'next/router';
import hash from 'hash-sum';
import { useUserData } from '../../hooks/user-data';
import { isEqual } from '../../utils/isEqual';
import { memoize } from '../../utils/memoize-one';
import { toAsUrl } from '../../utils/rewrites';
import { createDeepState } from '../deep-state';
import { useLocale } from '../locale';
import type { ListingsParams } from '../../shared/AggregatesListings/listingsDescriptor';
import { useRouter } from './RouterContext';

export const DELETE_SYMBOL: symbol = Symbol('DELETE');

const {
  useStore,
  Provider: StoreStateProvider,
  useSelector,
  useReduce,
} = createDeepState<{
  [string]: string,
  ...
}>();

type SerializedTypeInfo = { [string]: string | typeof DELETE_SYMBOL, ... };

opaque type DescriptorTypeInfo = {|
  keys: $ReadOnlyArray<string>,
  serialize: (obj: Object) => SerializedTypeInfo,
  deserialize: ({ [string]: string, ... }) => Object,
|};

type PartialObject<T> = $Shape<$ObjMap<T, <V>(V) => ?V>>;

export type Descriptor<T> = {|
  _typeInfo: DescriptorTypeInfo,
  getState: ({ [string]: string, ... }) => T,
  defaults: T,
  //             value           setValue            flush
  useState: () => [
    T,
    (PartialObject<T>) => void,
    (options?: FlushOptions) => void,
  ],
  useInitial: () => T,
  getQueryParams: (PartialObject<T>) => { [string]: string, ... },
  useHash: () => string,
  useIsEmpty: () => boolean,
  useHasChanged: () => boolean,
|};

type CreateDescriptor = (<T: Object>(obj: Descriptor<T>) => Descriptor<T>) &
  (<T: Object>(obj: T) => Descriptor<T>) &
  (<T: Object>(
    obj: T,
    serialize: (T) => { [string]: ?string, ... },
    deserialize: ({ [string]: string, ... }) => T,
  ) => Descriptor<T>) &
  (<A: Object, B: Object>(
    Descriptor<A>,
    Descriptor<B>,
  ) => Descriptor<{| ...A, ...B |}>) &
  (<A: Object, B: Object, C: Object>(
    Descriptor<A>,
    Descriptor<B>,
    Descriptor<C>,
  ) => Descriptor<{| ...A, ...B, ...C |}>) &
  (<A: Object, B: Object, C: Object, D: Object>(
    Descriptor<A>,
    Descriptor<B>,
    Descriptor<C>,
    Descriptor<D>,
  ) => Descriptor<{| ...A, ...B, ...C, ...D |}>) &
  (<A: Object, B: Object, C: Object, D: Object, E: Object>(
    Descriptor<A>,
    Descriptor<B>,
    Descriptor<C>,
    Descriptor<D>,
    Descriptor<E>,
  ) => Descriptor<{| ...A, ...B, ...C, ...D, ...E |}>) &
  (<A: Object, B: Object, C: Object, D: Object, E: Object, F: Object>(
    Descriptor<A>,
    Descriptor<B>,
    Descriptor<C>,
    Descriptor<D>,
    Descriptor<E>,
    Descriptor<F>,
  ) => Descriptor<{| ...A, ...B, ...C, ...D, ...E, ...F |}>) &
  (<
    A: Object,
    B: Object,
    C: Object,
    D: Object,
    E: Object,
    F: Object,
    H: Object,
  >(
    Descriptor<A>,
    Descriptor<B>,
    Descriptor<C>,
    Descriptor<D>,
    Descriptor<E>,
    Descriptor<F>,
    Descriptor<H>,
  ) => Descriptor<{| ...A, ...B, ...C, ...D, ...E, ...F, ...H |}>) &
  (<
    A: Object,
    B: Object,
    C: Object,
    D: Object,
    E: Object,
    F: Object,
    H: Object,
    I: Object,
  >(
    Descriptor<A>,
    Descriptor<B>,
    Descriptor<C>,
    Descriptor<D>,
    Descriptor<E>,
    Descriptor<F>,
    Descriptor<H>,
    Descriptor<I>,
  ) => Descriptor<{| ...A, ...B, ...C, ...D, ...E, ...F, ...H, ...I |}>) &
  (<
    A: Object,
    B: Object,
    C: Object,
    D: Object,
    E: Object,
    F: Object,
    H: Object,
    I: Object,
    J: Object,
  >(
    Descriptor<A>,
    Descriptor<B>,
    Descriptor<C>,
    Descriptor<D>,
    Descriptor<E>,
    Descriptor<F>,
    Descriptor<H>,
    Descriptor<I>,
    Descriptor<J>,
  ) => Descriptor<{| ...A, ...B, ...C, ...D, ...E, ...F, ...H, ...I, ...J |}>) &
  (<
    A: Object,
    B: Object,
    C: Object,
    D: Object,
    E: Object,
    F: Object,
    H: Object,
    I: Object,
    J: Object,
    K: Object,
  >(
    Descriptor<A>,
    Descriptor<B>,
    Descriptor<C>,
    Descriptor<D>,
    Descriptor<E>,
    Descriptor<F>,
    Descriptor<H>,
    Descriptor<I>,
    Descriptor<J>,
    Descriptor<K>,
  ) => Descriptor<{|
    ...A,
    ...B,
    ...C,
    ...D,
    ...E,
    ...F,
    ...H,
    ...I,
    ...J,
    ...K,
  |}>) &
  (<
    A: Object,
    B: Object,
    C: Object,
    D: Object,
    E: Object,
    F: Object,
    H: Object,
    I: Object,
    J: Object,
    K: Object,
    L: Object,
  >(
    Descriptor<A>,
    Descriptor<B>,
    Descriptor<C>,
    Descriptor<D>,
    Descriptor<E>,
    Descriptor<F>,
    Descriptor<H>,
    Descriptor<I>,
    Descriptor<J>,
    Descriptor<K>,
    Descriptor<L>,
  ) => Descriptor<{|
    ...A,
    ...B,
    ...C,
    ...D,
    ...E,
    ...F,
    ...H,
    ...I,
    ...J,
    ...K,
    ...L,
  |}>) &
  (<
    A: Object,
    B: Object,
    C: Object,
    D: Object,
    E: Object,
    F: Object,
    H: Object,
    I: Object,
    J: Object,
    K: Object,
    L: Object,
    M: Object,
  >(
    Descriptor<A>,
    Descriptor<B>,
    Descriptor<C>,
    Descriptor<D>,
    Descriptor<E>,
    Descriptor<F>,
    Descriptor<H>,
    Descriptor<I>,
    Descriptor<J>,
    Descriptor<K>,
    Descriptor<L>,
    Descriptor<M>,
  ) => Descriptor<{|
    ...A,
    ...B,
    ...C,
    ...D,
    ...E,
    ...F,
    ...H,
    ...I,
    ...J,
    ...K,
    ...L,
    ...M,
  |}>);

const getObjKeys = (
  obj: Object | Descriptor<Object>,
): $ReadOnlyArray<string> => {
  if (obj._typeInfo != null) {
    return obj._typeInfo.keys;
  }
  return Object.keys(obj);
};

const getKeys = (params: $ReadOnlyArray<Object | Descriptor<Object>>) =>
  Array.from(
    new Set(params.reduce((r, param) => [...r, ...getObjKeys(param)], [])),
  );

const defaultSerialize =
  (keys: $ReadOnlyArray<string>, defaultValues) =>
  (obj: Object): SerializedTypeInfo =>
    keys.reduce((r, key) => {
      if (!(key in obj)) {
        return r;
      }
      r[key] =
        JSON.stringify(obj[key]) === JSON.stringify(defaultValues[key])
          ? DELETE_SYMBOL
          : JSON.stringify(obj[key]);
      return r;
    }, {});

const isParseable = (str: string) => {
  try {
    JSON.parse(str);
    return true;
  } catch (e) {
    return false;
  }
};

const defaultDeserialize =
  (keys: $ReadOnlyArray<string>, defaultValues) =>
  (obj: { [string]: string, ... }) =>
    keys.reduce(
      (r, key) => ({
        ...r,
        [key]:
          obj[key] != null && isParseable(obj[key])
            ? JSON.parse(obj[key])
            : defaultValues[key],
      }),
      {},
    );

const getSerialize =
  (params: $ReadOnlyArray<Descriptor<Object>>) =>
  (obj: Object): SerializedTypeInfo => {
    return params.reduce(
      (r, param) => ({
        ...r,
        ...param._typeInfo.serialize(obj),
      }),
      ({}: SerializedTypeInfo),
    );
  };

const getDeserialize =
  (params: $ReadOnlyArray<Descriptor<Object>>) =>
  (obj: { [string]: string, ... }) => {
    return params.reduce(
      (r, param) => ({
        ...r,
        ...param._typeInfo.deserialize(obj),
      }),
      {},
    );
  };

type FlushOptions = {|
  pathname?: string,
  isOpenNewWindow?: boolean,
  method?: 'push' | 'replace',
|};

const DescriptorContext = React.createContext<{|
  // setParams: (SerializedTypeInfo) => void,
  isInVDOMTree: boolean,
  flush: (?FlushOptions) => void,
|}>({
  isInVDOMTree: false,
  flush: () => {},
});

export const AggregatorCustomPagesFilterContext: React.Context<
  ?ListingsParams | null,
> = React.createContext(null);

export const createDescriptor: CreateDescriptor = ((
  ...params: $ReadOnlyArray<Descriptor<Object> | Object>
) => {
  const keys = getKeys(params);

  const serialize =
    params[0]._typeInfo == null
      ? typeof params[1] === 'function'
        ? params[1]
        : defaultSerialize(keys, params[0])
      : getSerialize(params);

  const deserialize =
    params[0]._typeInfo == null
      ? typeof params[2] === 'function'
        ? params[2]
        : defaultDeserialize(keys, params[0])
      : getDeserialize(params);

  const deserializeM = memoize(deserialize);
  const identity = v => v;
  const identityM = memoize(identity);

  // TODO: rewrite on subcriptions, too many context updates
  const useState = () => {
    const { isInVDOMTree, flush } = React.useContext(DescriptorContext);
    const reduce = useReduce();

    if (isInVDOMTree === false) {
      throw Error(
        'DescriptorStore must be placed at some parent to use this hook',
      );
    }

    const setParams = newParams => {
      reduce(prevParams => {
        // $FlowFixMe[incompatible-type] string value is incompatible with string | symbol
        const r = { ...prevParams, ...newParams };
        Object.entries(r)
          .filter(([, v]) => v === DELETE_SYMBOL)
          .forEach(([key]) => {
            delete r[key];
          });
        return r;
      });
    };

    const state = useSelector(params => identityM(deserializeM(params)));

    return [state, values => setParams(serialize(values)), flush];
  };

  const useHash = () => {
    const [state] = useState();

    return hash(state);
  };

  const getState = (query: { [string]: string, ... }) => deserializeM(query);

  const useInitial = () => {
    const router = useRouter();
    return getState(router.query);
  };

  const defaults = deserialize({});

  const useIsEmpty = () => {
    const equal = useSelector(params => {
      const value = deserialize(params);
      return isEqual(defaults, value);
    });

    return equal;
  };

  const useHasChanged = () => {
    const filters = React.useContext(AggregatorCustomPagesFilterContext);
    const router = useRouter();
    const equal = useSelector(params => {
      const value = deserialize(params);
      const queryValue = getState(router.query);
      return isEqual(value, queryValue);
    });

    return filters ? false : !equal;
  };

  const getQueryParams = values => {
    const params = serialize(values);
    Object.entries(params)
      .filter(([, v]) => v === DELETE_SYMBOL)
      .forEach(([key]) => {
        delete params[key];
      });
    return params;
  };

  return {
    _typeInfo: { keys, serialize, deserialize },
    getQueryParams,
    useState,
    useHash,
    useIsEmpty,
    useHasChanged,
    useInitial,
    getState,
    defaults,
  };
}: any);

const useLayoutEffect =
  typeof window !== 'undefined' ? React.useLayoutEffect : React.useEffect;

type DescriptorStoreProps = {|
  children: React.Node,
  filterProps?: ListingsParams | null,
|};

export const DescriptorStore = ({
  children,
  filterProps,
}: DescriptorStoreProps): React.Node => {
  const router = useRouter();
  const routerRef = React.useRef(router);
  const { language } = useLocale();
  const { routes } = useUserData();
  const store = useStore(router.query);

  useLayoutEffect(() => {
    routerRef.current = router;
  }, [router]);

  React.useEffect(() => {
    const routeChangeComplete = () => {
      const params = store.get();
      if (!isEqual(routerRef.current.query, params)) {
        if (params.slug == null && router.query.slug == null) {
          store.set(() => router.query);
        }
      }
    };

    NextRouter.events.on('routeChangeComplete', routeChangeComplete);

    return () => {
      NextRouter.events.off('routeChangeComplete', routeChangeComplete);
    };
  });

  const flushObj = React.useMemo(
    () => ({
      flush: options => {
        let pathname = options?.pathname ?? '';
        const isOpenNewWindow = options?.isOpenNewWindow ?? false;
        const method = options?.method ?? 'push';
        // We call side effects inside setState to not wait full render
        // but to be sure that previous setState is finished
        const params = store.get();

        const sortedParams = Object.keys(params)
          .sort()
          .reduce((r, k) => {
            r[k] = params[k];
            return r;
          }, {});

        if (filterProps && routerRef.current.pathname === '/popular-searches') {
          pathname = '/listings';
          sortedParams['slug'] = null;
        }

        if (isOpenNewWindow) {
          const urlNew = toAsUrl(
            routes,
            language,
            {
              pathname: pathname ? pathname : routerRef.current.pathname,
              query: sortedParams,
            },
            routerRef.current,
          );
          window.open(urlNew.as);
        } else {
          if (method === 'push') {
            routerRef.current.push(
              {
                pathname: pathname ? pathname : routerRef.current.pathname,
                query: sortedParams,
              },
              null,
              {
                shallow: false,
              },
            );
          } else {
            routerRef.current.replace(
              {
                pathname: pathname ? pathname : routerRef.current.pathname,
                query: sortedParams,
              },
              null,
              {
                shallow: false,
              },
            );
          }
        }
      },
      isInVDOMTree: true,
    }),
    [store, routes, filterProps, language],
  );

  return (
    <StoreStateProvider store={store}>
      <AggregatorCustomPagesFilterContext.Provider value={filterProps}>
        <DescriptorContext.Provider value={flushObj}>
          {children}
        </DescriptorContext.Provider>
      </AggregatorCustomPagesFilterContext.Provider>
    </StoreStateProvider>
  );
};
