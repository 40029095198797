// @flow

import { type Language } from '../../../language.js';
import { isReference } from '../../reference';
import { translations } from './translations';
import type { ParamsAndQuery, Query } from './index';

export const pathname = '/listing';

const makeSubstitutions = t => {
  const items = [
    [{ offerType: JSON.stringify('buy') }, t.buy, '"buy"'],
    [{ offerType: JSON.stringify('rent') }, t.rent, '"rent"'],
    [
      { compositePropertyType_eq: JSON.stringify('HOUSE_APPT') },
      t.houseApartment,
      '"HOUSE_APPT"',
    ],
    [{ compositePropertyType_eq: JSON.stringify('HOUSE') }, t.house, '"HOUSE"'],
    [
      { compositePropertyType_eq: JSON.stringify('APPT') },
      t.apartment,
      '"APPT"',
    ],
    [{ compositePropertyType_eq: JSON.stringify('ROOM') }, t.room, '"ROOM"'],
    [
      { compositePropertyType_eq: JSON.stringify('COMMERCIAL') },
      t.commercial,
      '"COMMERCIAL"',
    ],
    [{ compositePropertyType_eq: JSON.stringify('PARK') }, t.parking, '"PARK"'],
    [{ compositePropertyType_eq: JSON.stringify('PROP') }, t.plot, '"PROP"'],
    [
      { compositePropertyType_eq: JSON.stringify('GASTRO') },
      t.hospitality,
      '"GASTRO"',
    ],
    [
      { compositePropertyType_eq: JSON.stringify('BUILDING') },
      t.building,
      '"BUILDING"',
    ],
    [
      { compositePropertyType_eq: JSON.stringify('OTHER') },
      t.otherType,
      '"OTHER"',
    ],

    ...[1, 2, 3, 4, 5, 6].map(n => [
      { numberOfRooms_gte: JSON.stringify(n) },
      t.rooms(n, null),
      `${n}`,
    ]),

    ...[
      [1, 1],
      [1, 2],
      [1, 3],
      [1, 4],
      [1, 5],
      [2, 2],
      [2, 3],
      [2, 4],
      [2, 5],
      [3, 3],
      [3, 4],
      [3, 5],
      [4, 4],
      [4, 5],
      [5, 5],
    ].map(([from, to]) => [
      {
        numberOfRooms_gte: JSON.stringify(from),
        numberOfRooms_lte: JSON.stringify(to + 0.5),
      },
      t.rooms(from, to),
      `${from}${to + 0.5}`,
    ]),
  ];

  return {
    keyToParam: new Map(items.map(([, v, k]) => [k, v])),
    paramToQuery: new Map(items.map(([v, k]) => [k, v])),
  };
};

const substitutionsByLang = {
  en: makeSubstitutions(translations.en),
  fr: makeSubstitutions(translations.fr),
  de: makeSubstitutions(translations.de),
  it: makeSubstitutions(translations.it),
  es: makeSubstitutions(translations.es),
};

const serializePlaces = placeSlugs => {
  return placeSlugs.map(place => place.slug).join('~');
};

const deserializePlaces = (str, lang) => {
  return str == null
    ? []
    : str
        .split('~')
        .map(slug => ({
          slug,
          lang,
        }))
        .filter(Boolean);
};

export const isListingSlug = (str: string): boolean => {
  // 1204-pully-8LRV-XOV8 -> 8LRV-XOV8
  const slugId = str.split('-').slice(-2).join('-');
  return isReference(slugId);
};

const safeJsonParse = str => {
  try {
    return JSON.parse(str);
  } catch (e) {
    return null;
  }
};

export const canHandleExternal = (
  lng: Language,
  { params }: ParamsAndQuery,
): boolean => {
  const t = translations[lng];

  const [first] = params;

  return (
    (first === t.buy || first === t.rent) &&
    isListingSlug(params[params.length - 1])
  );
};

export const externalToInternal = (
  lng: Language,
  { params, query }: ParamsAndQuery,
): Query => {
  const t = translations[lng];
  const substitutions = substitutionsByLang[lng];
  const { placeSlugs, places, ...rest } = query;

  let decoded: any = {};

  const slugs = [];

  params.filter(Boolean).forEach(param => {
    const query = substitutions.paramToQuery.get(param);
    if (query != null) {
      decoded = { ...decoded, ...query };
      return;
    }

    if (param.startsWith(t.page)) {
      const num = Number.parseFloat(param.slice(t.page.length));
      if (Number.isInteger(num)) {
        decoded.page = String(num - 1);
        return;
      }
    }

    // Only fo detail listings
    if (isListingSlug(param)) {
      const listingSlug = param;
      decoded = { ...decoded, listingSlug };
      return;
    }

    slugs.push(param);
  });

  // For listings search
  if (placeSlugs == null) {
    if (slugs.length === 1) {
      decoded.placeSlugs = JSON.stringify([{ slug: slugs[0], lang: lng }]);
    }
  } else {
    decoded.placeSlugs = JSON.stringify(deserializePlaces(placeSlugs, lng));
  }

  // For detailed listings
  if (decoded.listingSlug) {
    const { compositePropertyType_eq, offerType, listingSlug } = decoded;
    return {
      compositePropertyType_eq,
      offerType,
      listingSlug: JSON.stringify(listingSlug),
    };
  }

  return { ...rest, ...decoded };
};

export const internalToExternal = (
  lng: Language,
  query: Query,
): ParamsAndQuery => {
  const t = translations[lng];
  const substitutions = substitutionsByLang[lng];
  const getSubstitution = key => {
    return substitutions.keyToParam.get(key);
  };

  const {
    numberOfRooms_gte,
    numberOfRooms_lte,
    compositePropertyType_eq,
    offerType,
    page,
    placeSlugs,
    places,
    listingSlug,
    ...rest
  } = query;

  const propertyTypeParam = getSubstitution(compositePropertyType_eq);

  const offerTypeParam = getSubstitution(offerType) ?? t.buy;

  const roomsParam = getSubstitution(
    numberOfRooms_gte + (numberOfRooms_lte != null ? numberOfRooms_lte : ''),
  );

  const p = safeJsonParse(placeSlugs) || [];
  const placeSlug = p.length === 1 ? [p[0].slug] : null;
  const pageNumber = Number.parseFloat(page);
  const pageParam =
    !Number.isInteger(pageNumber) || pageNumber === 0
      ? null
      : `${t.page}${pageNumber + 1}`;

  const placeParams = placeSlug == null ? [] : placeSlug;
  if (listingSlug) {
    const params = [
      offerTypeParam,
      propertyTypeParam,
      safeJsonParse(listingSlug),
    ].filter(Boolean);

    return { params, query: rest };
  }

  const params = [
    offerTypeParam,
    ...placeParams,
    propertyTypeParam,
    roomsParam,
    pageParam,
  ].filter(Boolean);

  const newQuery: { [string]: string, ... } = { ...rest };
  // In case values are not suitable for usage in params, return them back to query
  if (propertyTypeParam == null && compositePropertyType_eq != null) {
    newQuery.compositePropertyType_eq = compositePropertyType_eq;
  }
  if (roomsParam == null && numberOfRooms_gte != null) {
    newQuery.numberOfRooms_gte = numberOfRooms_gte;
  }
  if (roomsParam == null && numberOfRooms_lte != null) {
    newQuery.numberOfRooms_lte = numberOfRooms_lte;
  }
  if (placeSlug == null && placeSlugs != null) {
    newQuery.placeSlugs = serializePlaces(p);
  }

  return { params, query: newQuery };
};
