// @flow

import {
  type Language,
  type PrismicLanguage,
  list as languages,
  prismicLocales,
} from '../../../language.js';
import type { StrictObjHref } from '../rewrites';
import { translations } from './translations';
import * as listings from './listings';
import * as listing from './listing';

// This interceptor transforms href objects like this:
//
// {
//   pathname: '/listings',
//   query: {offerType: 'buy', page: '2'}
// }
//
// To ones like this:
//
// {
//   pathname: '/interceptor-buy',
//   query: {param1: 'page-2'}
// }
//
// And vice versa.

const handlers = [listings, listing];

export const rewrites: $ReadOnlyArray<{|
  lang: PrismicLanguage,
  page: string,
  path: string,
  query?: { [string]: string, ... },
|}> = [
  ...['buy', 'rent']
    .map(prefix =>
      languages
        .map(lang =>
          prismicLocales[lang].map(prismicLocale => ({
            path: `/${translations[lang][prefix]}/:param1?/:param2?/:param3?/:param4?/:param5?/:param6?`,
            page: `interceptor-${prefix}`,
            lang: prismicLocale,
            query: {},
          })),
        )
        .reduce((acc, cur) => [...acc, ...cur], []),
    )
    .reduce((acc, cur) => [...acc, ...cur], []),
];

export type Query = $ElementType<StrictObjHref, 'query'>;

export type ParamsAndQuery = {|
  params: $ReadOnlyArray<string>,
  query: Query,
|};

const externalToInternal_ = (
  lng: Language,
  href: StrictObjHref,
): StrictObjHref => {
  const {
    param1,
    param2,
    param3,
    param4,
    param5,
    param6,
    ...query
  } = href.query;

  const params = [param1, param2, param3, param4, param5, param6].filter(
    Boolean,
  );

  const t = translations[lng];

  const firstParam = {
    '/interceptor-buy': t.buy,
    '/interceptor-rent': t.rent,
  }[href.pathname];

  const notFound = {
    // triggering 404 error by using a page name that does not exist
    pathname: '/non-existing',
    query: {},
  };

  if (firstParam != null) {
    const paramsAndQuery = { params: [firstParam, ...params], query };
    for (const handler of handlers) {
      if (handler.canHandleExternal(lng, paramsAndQuery)) {
        return {
          pathname: handler.pathname,
          query: handler.externalToInternal(lng, paramsAndQuery),
        };
      }
    }

    return notFound;
  }

  // if user tries to use internal URL directly return 404,
  // to avoid duplicate content etc.
  if (handlers.map(x => x.pathname).includes(href.pathname)) {
    return notFound;
  }

  return href;
};

export const externalToInternal = (
  lng: Language,
  href: StrictObjHref,
): {| +pathname: string, +query: { [string]: string, ... } |} => {
  const newHref = externalToInternal_(lng, href);
  // console.log(
  //   'externalToInternal',
  //   lng,
  //   JSON.stringify(href),
  //   JSON.stringify(newHref)
  // );
  return newHref;
};

const internalToExternal_ = (
  lng: Language,
  href: StrictObjHref,
): StrictObjHref => {
  const {
    pathname,
    query: { uid, ...query },
  } = href;
  const t = translations[lng];

  const handler = handlers.find(x => x.pathname === pathname);

  if (handler != null) {
    const {
      params: [firstParam, ...params],
      query: newQuery,
    } = handler.internalToExternal(lng, query);

    params.forEach((value, i) => {
      newQuery[`param${i + 1}`] = value;
    });

    if (firstParam === t.rent) {
      return { pathname: `/interceptor-rent`, query: newQuery };
    }

    if (firstParam === t.buy) {
      return { pathname: `/interceptor-buy`, query: newQuery };
    }

    throw new Error(
      `Unsuported first param. Should be one of ${t.buy}, ${t.rent}. Got: ${firstParam}`,
    );
  }

  return href;
};

export const internalToExternal = (
  lng: Language,
  href: StrictObjHref,
): {| +pathname: string, +query: { [string]: string, ... } |} => {
  const newHref = internalToExternal_(lng, href);
  // console.log(
  //   'internalToExternal',
  //   lng,
  //   JSON.stringify(href),
  //   JSON.stringify(newHref)
  // );
  return newHref;
};
