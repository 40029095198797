// @flow

import * as React from 'react';
import { type DeepState, createDeepState } from '../controls/deep-state';

type ViewportType = 'unknown' | 'mobile' | 'desktop';

const viewportTypeDeepState: DeepState<ViewportType> = createDeepState();

export const {
  useStore,
  Provider: StateProvider,
  useSelector,
} = viewportTypeDeepState;

const makeQuery = value => {
  const convertedValue =
    typeof value === 'number' ? `${Math.ceil(value / 16)}em` : value;
  return `screen and (min-width: ${convertedValue})`;
};

export const ViewportTypeProvider = ({
  children,
}: {|
  children: React.Node,
|}): React.Node => {
  const store = useStore('unknown');

  React.useEffect(() => {
    const handleWindowResize = () => {
      if (window.matchMedia(makeQuery(768)).matches) {
        store.set(() => 'desktop');
      } else {
        store.set(() => 'mobile');
      }
    };

    handleWindowResize();
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, [store]);

  return <StateProvider store={store}>{children}</StateProvider>;
};

export const useViewportType = (): ViewportType => {
  return useSelector(s => s);
};
