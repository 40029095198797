// @flow

import { type Logger, makeLogger } from '../log';
import { useUserData } from './user-data.js';

// A single object is reused in browser for two reasons:
// 1. small optimization
// 2. (more important) to allow to safely use it as a dependency in useEffect
const browserLogger = makeLogger(null);

export const useLog = (): Logger => {
  if (typeof window !== 'undefined') {
    return browserLogger;
  }

  // it's ok to break hooks rules here
  // because condition above is always the same during any component lifetime
  //
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const userData = useUserData();

  return makeLogger(userData.ssrCloudLogsTrace);
};
