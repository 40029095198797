// @flow

import { parse as parseUrl } from 'url';
export { format } from 'url';
import { type Language, list as languages } from '../../language.js';

export const parse = (
  urlStr: string,
): {| pathname: string, query: { [string]: string, ... } |} => {
  const { pathname, query } = parseUrl(urlStr || '/', true);
  if (query == null) {
    throw new Error('query is always an object');
  }
  return { pathname: pathname != null ? pathname : '/', query };
};

export const hasProtocol = (urlStr: string): boolean => {
  const res = parseUrl(urlStr || '/', true);
  return res.protocol != null;
};

export const parseLanguage = (
  pathname: string,
): {| lng: ?Language, pathname: string |} => {
  pathname = pathname.startsWith('/') ? pathname : `/${pathname}`;

  for (const language of languages) {
    if (pathname === `/${language}`) {
      return { lng: language, pathname: '/' };
    }
  }

  const start = pathname.slice(0, 4);
  const rest = pathname.slice(3);

  for (const language of languages) {
    if (start === `/${language}/`) {
      return { lng: language, pathname: rest };
    }
  }

  return { lng: null, pathname };
};
