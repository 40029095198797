// @flow

import * as React from 'react';
import Head from 'next/head';
import { graphql, useLazyLoadQuery } from 'react-relay';
import { useNoSSR } from '../../hooks/nossr';
import { type TrackMessage } from './TrackMessage';
import type { analyticsMeQuery } from './__generated__/analyticsMeQuery.graphql';

type Analytics = {|
  track: <K: $Keys<TrackMessage>>(
    key: K,
    message: $ElementType<TrackMessage, K>,
  ) => void,
|};

const AnalyticsContext = React.createContext<Analytics>({
  track: () => {},
});

export const useAnalytics = (): Analytics => React.useContext(AnalyticsContext);

type AnalyticsProviderProps = {|
  children: React.Node,
  googleTagManagerId: string | null,
|};

const GTM_INIT_DATA_LAYER_HTML = {
  __html: `
    window.dataLayer = window.dataLayer || [
      { 'gtm.start': new Date().getTime(), event: 'gtm.js' },
    ];
  `,
};

const TrackUserData = ({ data }) => {
  React.useEffect(() => {
    const me = data?.me;

    if (me != null) {
      const user = {
        userId: me.id,
        firstName: me.firstName,
        lastName: me.lastName,
        phone: me.primaryPhoneNumber?.number,
        email: me.primaryEmail?.email,
        language: me.language,
      };

      window.dataLayer.push({
        event: 'RA.Web.Identify',
        ...user,
        user,
      });
    }

    window.dataLayer.push({
      event: 'datalayer_ready',
      app: 'realadvisor.aggregator',
      user_data: {
        user_id: data?.me?.id,
        first_name: data?.me?.firstName,
        last_name: data?.me?.lastName,
        phone: data?.me?.primaryPhoneNumber?.number,
        email: data?.me?.primaryEmail?.email,
        language: data?.me?.language,
      },
    });
  }, [data]);

  return null;
};

const TrackUser = () => {
  const data = useLazyLoadQuery<analyticsMeQuery>(
    graphql`
      query analyticsMeQuery {
        me {
          id
          firstName
          lastName
          primaryEmail {
            email
          }
          primaryPhoneNumber {
            number
          }
          language
        }
      }
    `,
    {},
  );
  return <TrackUserData data={data} />;
};

export const AnalyticsProvider = (
  props: AnalyticsProviderProps,
): React.Node => {
  const noSsr = useNoSSR();

  const contextValue = React.useMemo(
    () => ({
      track: <K: $Keys<TrackMessage>>(key: K, message) => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: key,
          ...message,
          properties: message,
        });
      },
    }),
    [],
  );

  return (
    <AnalyticsContext.Provider value={contextValue}>
      <Head>
        <script dangerouslySetInnerHTML={GTM_INIT_DATA_LAYER_HTML}></script>
        {props.googleTagManagerId != null && (
          <>
            {/* prefetch improves page tti and seems like doesnt affect other lighthouse params */}
            <link
              rel="prefetch"
              as="script"
              href="https://www.google-analytics.com/analytics.js"
            />
          </>
        )}
      </Head>
      {props.children}
      {noSsr && (
        <React.Suspense fallback={null}>
          <TrackUser />
        </React.Suspense>
      )}
    </AnalyticsContext.Provider>
  );
};
