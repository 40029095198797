// @flow

import * as React from 'react';
import { theme } from './theme.js';

const useContext: <T>(React.Context<T>) => T = (React: any).useContext;

const ThemeContext: React.Context<typeof theme> = React.createContext(theme);

export const ThemeProvider = ThemeContext.Provider;

export const useTheme = (): typeof theme => useContext(ThemeContext);
