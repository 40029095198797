// @flow

/* global global */

import { format } from 'url';
import { Environment, Network, RecordSource, Store } from 'relay-runtime';
import { type Emitter, createNanoEvents } from 'nanoevents';
import type { ErrorsEmitter } from '@realadvisor/error';
import {
  loginAnonymously,
  loginByMagicToken,
  refreshToken,
} from '@realadvisor/auth-client';
import { joinURL } from '../../utils/rewrites/joinUrl';
import { type UserData } from '../../server/UserData';
import { startTimeLog } from '../../server/measure';
import { makeLogger, getCloudLogsTrace } from '../../log';
import { ssrHeadersForAPIServer } from './server-headers';

export const emitter: Emitter<{|
  'runtime-server-error': [string],
|}> = createNanoEvents();

export const errorsEmitter: ErrorsEmitter = createNanoEvents();

export type CreateRelayEnvironmentOptions = {|
  records?: ?Object,
  userData: UserData,
  request?: http$IncomingMessage<>,
|};

const cache_ = new Map<string, any>();

const createNetwork = (userData: UserData, serverHeaders, logger) => {
  const apiOrigin =
    typeof window === 'undefined'
      ? userData.serverApiOrigin
      : userData.clientApiOrigin;

  return Network.create(async (operation, variables) => {
    const endTimeLog = startTimeLog(`fetch ${operation.name}`, logger);

    // In profiling mode we cache all requests
    const profilingCacheKey = userData.isProfiling
      ? JSON.stringify({
          documentId: operation.id,
          query: operation.text,
          variables,
          name: operation.name,
        })
      : '';

    if (userData.isProfiling) {
      if (cache_.has(profilingCacheKey)) {
        logger.info(`cache for operation ${operation.name} found`);
        return (cache_.get(profilingCacheKey): any);
      }
    }

    const headers: { [key: string]: string, ... } = {
      ...serverHeaders,
      'Content-Type': 'application/json',
      Accept: 'application/json',
    };

    const query = async () => {
      // // support login only on client side
      if (typeof localStorage !== 'undefined') {
        let access_token = localStorage.getItem('access_token');
        const refresh_token = localStorage.getItem('refresh_token');
        const url = new URL(window.location.href);
        const token = url.searchParams.get('token');
        if (access_token == null) {
          if (refresh_token != null) {
            await refreshToken();
          } else if (token != null) {
            await loginByMagicToken();
          } else {
            await loginAnonymously();
          }
        }

        access_token = localStorage.getItem('access_token');
        headers.Authorization = `Bearer ${access_token ?? ''}`;
        // x-realadvisor-auth is used to avoid overriding Authorization header
        // with basic auth by safari
        headers['x-realadvisor-auth'] = `Bearer ${access_token ?? ''}`;
      }

      return fetch(
        format({
          pathname: joinURL(apiOrigin, 'graphql'),
          query: {
            lng: userData.language,
          },
        }),
        {
          method: 'POST',
          headers,
          credentials: 'include',
          body:
            operation.id != null
              ? JSON.stringify({
                  documentId: operation.id,
                  variables,
                  name: operation.name,
                })
              : JSON.stringify({
                  query: operation.text,
                  variables,
                  name: operation.name,
                }),
          agent: global.agents
            ? userData.protocol === 'https'
              ? global.https
              : global.http
            : null,
        },
      )
        .then(response => {
          if (!response.ok) {
            const { status, statusText } = response;
            logger.error(new Error('Bad response'), status, statusText);

            return response.text().then(async t => {
              logger.error(
                new Error('Bad response body'),
                t != null ? t.substr(0, 200) : '',
              );

              try {
                const obj = JSON.parse(t);
                if (obj.errors) {
                  if (obj.errors?.[0]?.message?.startsWith('JWT is expired')) {
                    const newToken = localStorage.getItem('access_token');
                    // here we check that token is still the same, because another refreshToken could have been called
                    if (headers.Authorization === `Bearer ${newToken ?? ''}`) {
                      // token is still the same, refresh it
                      await refreshToken();
                    }
                    // retry query with new token
                    return query();
                  }

                  return obj;
                } else {
                  throw new Error(
                    `Bad response code=${status} message=${statusText}`,
                  );
                }
              } catch (e) {
                throw new Error(
                  `Bad response code=${status} message=${statusText}`,
                );
              }
            });
          }

          return response.json();
        })
        .then(json => {
          if (json.redirect) {
            if (typeof window !== 'undefined') {
              window.location = json.redirect;
            }
          }

          if (userData.isProfiling) {
            cache_.set(profilingCacheKey, json);
          }

          return json;
        })

        .catch(errorOrJson => {
          if (errorOrJson instanceof Error) {
            emitter.emit('runtime-server-error', errorOrJson.message);

            throw {
              errors: [
                {
                  message: errorOrJson.message,
                },
              ],
              data: null,
            };
          } else {
            errorsEmitter.emit(
              'add',
              operation.name,
              errorOrJson.errors ?? [{ message: 'unknown fetch error' }],
            );
            throw errorOrJson;
          }
        })
        .finally(() => {
          endTimeLog();
        });
    };

    return query();
  });
};

export const createRelayEnvironment = (
  options: CreateRelayEnvironmentOptions,
): Environment => {
  const { records, userData, request } = options;
  const store = new Store(new RecordSource((records: any)));

  const logger = makeLogger(request && getCloudLogsTrace(request));

  const ssrHeaders = request != null ? ssrHeadersForAPIServer(request) : null;
  const serverHeaders: { [string]: string, ... } = {
    ...ssrHeaders,
  };

  const network = createNetwork(userData, serverHeaders, logger);

  return new Environment({ store, network });
};
