// @flow */

import * as React from 'react';

export const useLocalStorageForDevelopmentPurposeOnly = (
  key: string,
): string | null => {
  const [value, setValue] = React.useState(null);

  React.useEffect(() => {
    const value = localStorage.getItem(key);
    setValue(value ?? null);
  }, [key]);

  return value;
};
