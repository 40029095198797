// @flow

export const size = (px: number): string => px / 16 + 'rem';

type Style = { [string]: number | string | Style, ... };

export const commonTextStyle = {
  marginTop: 0,
  marginBottom: 0,
  cursor: 'inherit',
  /* fixes webkit font-boosting heuristic */
  maxHeight: 9999999,
};

// https://material.io/design/typography
export const light = '300';
export const regular = 'normal';
export const medium = '600';
export const bold = '700';

export const text = {
  h1: ({
    ...commonTextStyle,
    fontWeight: medium,
    fontSize: size(64),
    lineHeight: size(72),
    letterSpacing: -2,
  }: Style),
  h2: ({
    ...commonTextStyle,
    fontWeight: medium,
    fontSize: size(48),
    lineHeight: size(56),
  }: Style),
  h3: ({
    ...commonTextStyle,
    fontWeight: medium,
    fontSize: size(40),
    lineHeight: size(48),
  }: Style),
  h4: ({
    ...commonTextStyle,
    fontWeight: bold,
    fontSize: size(28),
    lineHeight: size(40),
  }: Style),
  h5: ({
    ...commonTextStyle,
    fontWeight: bold,
    fontSize: size(24),
    lineHeight: size(34),
  }: Style),
  h6: ({
    ...commonTextStyle,
    fontWeight: bold,
    fontSize: size(20),
    lineHeight: size(28),
  }: Style),
  subtitle1: ({
    ...commonTextStyle,
    fontWeight: medium,
    fontSize: size(16),
    lineHeight: size(24),
  }: Style),
  subtitle2: ({
    ...commonTextStyle,
    fontWeight: medium,
    fontSize: size(14),
    lineHeight: size(22),
  }: Style),
  body1: ({
    ...commonTextStyle,
    fontWeight: light,
    fontSize: size(16),
    lineHeight: size(24),
  }: Style),
  body2: ({
    ...commonTextStyle,
    fontWeight: light,
    fontSize: size(14),
    lineHeight: size(22),
  }: Style),
  button: ({
    ...commonTextStyle,
    fontWeight: medium,
    fontSize: size(16),
    lineHeight: 1.5,
    // textTransform: 'uppercase',
  }: Style),
  caption: ({
    ...commonTextStyle,
    fontWeight: light,
    fontSize: size(12),
    lineHeight: 1.66,
  }: Style),
  overline: ({
    ...commonTextStyle,
    fontWeight: regular,
    fontSize: size(10),
    lineHeight: 2.66,
    textTransform: 'uppercase',
  }: Style),
};
