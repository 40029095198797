// @flow

import * as React from 'react';
import dynamic from 'next/dynamic';
import { errorsEmitter } from '../controls/relay/createRelayEnvironment';
import { useLocalStorageForDevelopmentPurposeOnly } from '../hooks/local-storage-development';

const ErrorReporting = dynamic(() =>
  import('@realadvisor/error').then(m => m.ErrorReporting),
);

// subscribe on api errors, and show them
export const ErrorSubscriber = (props: {| debug: boolean |}): React.Node => {
  // Set it via dev console localStorage.setItem('debug', 'true')
  const debug = useLocalStorageForDevelopmentPurposeOnly('debug');

  return debug != null || props.debug ? (
    <ErrorReporting errors={[]} emitter={errorsEmitter} />
  ) : null;
};
