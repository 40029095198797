// @flow

import * as React from 'react';
import { Global, css } from '@emotion/react';
import { theme } from './theme';

export const GlobalStyle = (): React.Node => {
  return (
    <Global
      styles={css`
        body,
        html {
          margin: 0;
          height: 100%;
        }

        body {
          min-height: 100vh;
          min-height: -webkit-fill-available;
        }
        html {
          height: -webkit-fill-available;
        }

        html {
          -webkit-font-smoothing: antialiased;
          box-sizing: border-box;
          font-family: ${theme.fonts.sans};
        }

        body {
          color: ${theme.colors.highText};
          background-color: #fff;
        }

        *,
        *:before,
        *:after {
          box-sizing: inherit;
          /*
          fixes webkit font-boosting heuristic
          Uncomment if commonTextStyle maxHeight at text.js will not be enough
          max-height: 9999999px;
          */
        }

        #__next {
          min-height: 100%;
          display: flex;
        }

        p {
          margin-top: 0;
        }

        strong {
          font-weight: 600;
        }
      `}
    />
  );
};
