// @flow

const numberAccept = /[\d.]+/g;

const parseNumber = string => (string.match(numberAccept) || []).join('');

export function formatStringAsIntlNumber(
  num: string,
  locale?: string,
  numberFormatOptions?: Intl$NumberFormatOptions,
): string {
  return (0)
    .toLocaleString(locale, {
      ...numberFormatOptions,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
    .replace('0', num);
}

export const formatFloatingPointNumber = (
  value: string,
  minDigits: number,
  maxDigits: number,
): string => {
  const parsed = parseNumber(value);
  const [head, tail] = parsed.split('.');
  // Avoid rounding errors at toLocaleString as when user enters 1.239 and maxDigits=2 we
  // must not to convert it to 1.24, it must stay 1.23
  const scaledTail = tail != null ? tail.slice(0, maxDigits) : '';

  const number = Number.parseFloat(`${head}.${scaledTail}`);

  if (Number.isNaN(number)) {
    return '';
  }

  // de-CH here is intended. do not try to fix pls
  const formatted = number.toLocaleString('de-CH', {
    minimumFractionDigits: 0,
    maximumFractionDigits: maxDigits,
  });

  if (parsed.includes('.')) {
    const [formattedHead] = formatted.split('.');

    // skip zero at digits position for non fixed floats
    // as at digits 2 for non fixed floats numbers like 1.50 has no sense, just 1.5 allowed
    // but 1.0 has sense as otherwise you will not be able to enter 1.05 for example
    const formattedTail =
      scaledTail !== '' &&
      scaledTail[maxDigits - 1] === '0' &&
      minDigits <= maxDigits - 1
        ? scaledTail.slice(0, -1)
        : scaledTail;

    return formattedTail !== ''
      ? `${formattedHead}.${formattedTail}`
      : formattedHead;
  }
  return formatted;
};

export const numberShortener = (
  num: number,
  formatOptionsOverrides?: $ReadOnlyArray<{|
    min: 10 | 100 | 1000 | 10000 | 100000 | 1000000 | 10000000 | 100000000,
    base?: 10 | 100 | 1000 | 10000 | 100000 | 1000000 | 10000000 | 100000000,
    minDigits?: number,
    maxDigits?: number,
    postfix?: string,
  |}> | null,
  numberFormatOptions?: Intl$NumberFormatOptions,
  locale?: string,
): string => {
  const KILO_POSTFIX = 'k';
  const MILLION_POSTFIX = 'm';

  const TEN_MILLION = 10000000;
  const ONE_MILLION = 1000000;
  const TEN_THOUSANDS = 10000;
  const THOUSAND = 1000;

  const formatOptions = [
    {
      min: TEN_MILLION,
      base: ONE_MILLION,
      minDigits: 0,
      maxDigits: 1,
      postfix: MILLION_POSTFIX,
    },
    {
      min: ONE_MILLION,
      base: ONE_MILLION,
      minDigits: 0,
      maxDigits: 2,
      postfix: MILLION_POSTFIX,
    },
    {
      min: TEN_THOUSANDS,
      base: THOUSAND,
      minDigits: 0,
      maxDigits: 0,
      postfix: KILO_POSTFIX,
    },
    {
      min: THOUSAND,
      base: THOUSAND,
      minDigits: 0,
      maxDigits: 1,
      postfix: KILO_POSTFIX,
    },
  ];

  if (formatOptionsOverrides != null) {
    formatOptionsOverrides.forEach(opt => {
      const overridableOpt = formatOptions.find(fo => fo.min === opt.min);
      if (overridableOpt == null) {
        throw new Error('You can only override existing options');
      }
      Object.assign(overridableOpt, opt);
    });
  }

  formatOptions.sort((a, b) => Math.sign(b.min - a.min));

  let result = num.toString();

  for (let i = 0; i < formatOptions.length; i += 1) {
    const option = formatOptions[i];
    if (num >= option.min) {
      result = `${formatFloatingPointNumber(
        // we use toFixed as here we need rounding
        (num / option.base).toFixed(option.maxDigits),
        option.minDigits,
        option.maxDigits,
      )}${option.postfix}`;
      break;
    }
  }

  return numberFormatOptions?.currency != null
    ? formatStringAsIntlNumber(result, locale, numberFormatOptions)
    : `${result}`;
};
