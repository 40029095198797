// @flow

import { pathToRegexp } from 'path-to-regexp';
import type { Language } from '../../language.js';
import type { RoutesConfig } from '../../server/config.js';
import { prismicLocales } from '../../language.js';
import { matchPath } from './matchPath';
import { generatePath } from './generatePath';
import { parse, format, hasProtocol, parseLanguage } from './url';
import * as interceptor from './interceptor';

const findRewriteAndMatch = (rewrites, pathname) => {
  for (let i = 0; i !== rewrites.length; i += 1) {
    const match = matchPath(pathname, rewrites[i].path);

    if (match) {
      return {
        rewrite: rewrites[i],
        match,
      };
    }
  }
  return null;
};

export type StrictObjHref = {|
  +pathname: string,
  +query: { [string]: string, ... },
|};

export type ObjHref = {
  +pathname: string,
  +query?: { +[string]: string | null, ... },
  ...
};
export type Href = $Exact<ObjHref> | string;

const removeLastSlashFromPathname = (pathname: string) =>
  pathname === '/' || !pathname.endsWith('/')
    ? pathname
    : pathname.slice(0, -1);

const addLanguageToPathname = (language, pathname) => `/${language}${pathname}`;

// converts url like /en/products/1 to products?id=1 based on rewrites table
export const toPageHref = (
  cfg: RoutesConfig,
  pUrl: string,
  language: Language,
): {| pathname: string, query: { [string]: string, ... } |} => {
  let { pathname, query } = parse(pUrl);

  // FIXME MAYBE:
  //   It's strange that we ignore language in pathname, and use provided language instead.
  //   Perhaps we should use language from pathname, and the provided one as a fallback.
  pathname = parseLanguage(pathname).pathname;

  const currentLangRewrites = cfg.rewrites.filter(
    r => r.lang == null || prismicLocales[language].includes(r.lang),
  );

  const rMatch = findRewriteAndMatch(currentLangRewrites, pathname);

  // Nothing found, return as is
  if (rMatch == null) {
    return { pathname, query };
  }

  const {
    rewrite: { page },
    match: { params },
  } = rMatch;

  const newPathname = `/${page}`;

  const decodedParams: { [string]: string, ... } = Object.keys(params).reduce(
    (acc, key) => {
      if (params[key] != null) {
        // + replace is needed as we must behave same as for query string in params
        acc[key] = decodeURIComponent(params[key].replace(/\+/g, '%20'));
      }
      return acc;
    },
    {},
  );

  const baseResult = {
    pathname: newPathname === '' ? '/' : newPathname,
    query: { ...decodedParams, ...query },
  };

  const interceptorResult = interceptor.externalToInternal(
    language,
    baseResult,
  );

  const finalRewrite =
    baseResult.pathname === interceptorResult.pathname
      ? rMatch.rewrite
      : currentLangRewrites.find(
          x => `/${x.page}` === interceptorResult.pathname,
        );

  const finalRewriteQuery: { [string]: string, ... } =
    finalRewrite?.query ?? {};
  const langQuery: { [string]: string, ... } =
    finalRewrite?.lang == null ? {} : { lang: finalRewrite.lang };
  const finalResult = {
    pathname: interceptorResult.pathname,
    query: {
      ...interceptorResult.query,
      ...finalRewriteQuery,
      ...langQuery,
    },
  };

  return finalResult;
};

type RewriteKey = {|
  name: string,
  modifier: string,
|};

type Rewrites = $ReadOnlyArray<{|
  path: string,
  page: string,
  lang?: string,
  query?: Object,
|}>;

const pick = (obj, keys) => {
  const result = {};
  for (let i = 0; i < keys.length; i += 1) {
    const key = keys[i];
    if (key in obj) {
      result[key] = obj[key];
    }
  }
  return result;
};

const omitBy = (obj, fn: (v: string | null, k?: string) => boolean) => {
  const newObj = {};
  for (const k in obj) {
    if (!fn(obj[k], k)) {
      newObj[k] = obj[k];
    }
  }

  return newObj;
};

const fixAsPathname = (path, language) => {
  let result = path;
  if (!result.startsWith('/')) {
    result = `/${result}`;
  }
  result = addLanguageToPathname(language, result);
  result = removeLastSlashFromPathname(result);
  return result;
};

type PreparedRewrites = Array<{|
  keys: Array<RewriteKey>,
  path: string,
  page: string,
  lang?: string,
  query?: Object,
|}>;

type PreparedRewritesMaps = {|
  rewritesPathnameMap: { [string]: PreparedRewrites, ... },
  rewritesPathnameMapWithQuery: { [string]: PreparedRewrites, ... },
|};

const prepareRewrites = (
  rewrites: Rewrites,
  language: Language,
): PreparedRewritesMaps => {
  const rewritesPathnameMap = {};
  const rewritesPathnameMapWithQuery = {};

  // Sort rewrites by query length
  const sortedRewrites = rewrites.slice(0).sort((a, b) => {
    if (a.query != null && b.query != null) {
      return Object.keys(a.query).length - Object.keys(b.query).length;
    } else {
      return 0;
    }
  });

  sortedRewrites.forEach(r => {
    if (prismicLocales[language].includes(r.lang) || r.lang == null) {
      const keys: $ReadOnlyArray<RewriteKey> = [];
      pathToRegexp(r.path, keys, {
        delimiter: '/',
      });

      const preparedRewrite = {
        ...r,
        keys,
      };

      if (rewritesPathnameMap[`/${r.page}`] == null) {
        rewritesPathnameMap[`/${r.page}`] = [];
      }

      rewritesPathnameMap[`/${r.page}`].push(preparedRewrite);

      if (preparedRewrite.query != null) {
        if (rewritesPathnameMapWithQuery[`/${r.page}`] == null) {
          rewritesPathnameMapWithQuery[`/${r.page}`] = [];
        }

        rewritesPathnameMapWithQuery[`/${r.page}`].push(preparedRewrite);
      }
    }
  });

  return {
    rewritesPathnameMap,
    rewritesPathnameMapWithQuery,
  };
};

const lng2Rewrites: Map<
  string,
  WeakMap<Rewrites, PreparedRewritesMaps>,
> = new Map();

function prepareRewritesMemoized(
  rewrites: Rewrites,
  language: Language,
): PreparedRewritesMaps {
  if (!lng2Rewrites.has(language)) {
    lng2Rewrites.set(language, new WeakMap());
  }

  const langRewrites = lng2Rewrites.get(language);

  if (langRewrites != null) {
    if (langRewrites.has(rewrites)) {
      const prepared = langRewrites.get(rewrites);
      if (prepared != null) {
        return prepared;
      }
    }
  }

  const result = prepareRewrites(rewrites, language);

  lng2Rewrites.get(language)?.set(rewrites, result);
  return result;
}

export const toAsUrl = (
  cfg: RoutesConfig,
  language: Language,
  pageHref: Href,
  currentRoute: ObjHref,
): {|
  as: string,
  href: {| +pathname: string, +query: { [string]: string, ... } |},
|} => {
  const { rewritesPathnameMap, rewritesPathnameMapWithQuery } =
    prepareRewritesMemoized(cfg.rewrites, language);

  let { pathname, query } =
    typeof pageHref === 'string'
      ? toPageHref(cfg, pageHref, language)
      : pageHref;

  // Because of prismic we have to pick data from /index page rewrite
  if (pathname === '/') {
    pathname = '/index';
  }

  // pick query params from current route excluding params from new route query
  // so if we pass null for some param in query it will not be picked (see query filtered on nulls)
  const currentRoutePickedQuery = pick(
    currentRoute.query || {},
    cfg.peekKeysFromCurrentRoute,
  );

  // current query is combination of previous query params (see peekKeysFromCurrentRoute)
  // also null in query is used to omit params defined in peekKeysFromCurrentRoute
  const combinedQuery: { [string]: string, ... } = omitBy(
    Object.assign(currentRoutePickedQuery, query),
    v => v == null,
  );

  const internalHref = { pathname, query: combinedQuery };
  const externalHref = interceptor.internalToExternal(language, internalHref);

  // try to find the first rewrite where all rewrite.query keys and values are in "query"
  let rewrite;

  const queryRewrites =
    rewritesPathnameMapWithQuery[externalHref.pathname] ?? [];

  // Preserve query params from source rewrite if interceptor was used
  let sourceQuery = {};
  if (
    pathname !== externalHref.pathname &&
    rewritesPathnameMapWithQuery[pathname] != null
  ) {
    const sourceRewrite = rewritesPathnameMapWithQuery[pathname][0];

    if (sourceRewrite != null) {
      sourceQuery = sourceRewrite.query;
    }
  }

  if (queryRewrites.length > 0 && query != null) {
    rewrite = queryRewrites.find(r =>
      Object.keys(r.query || {}).every(
        key => r.query != null && r.query[key] === query[key],
      ),
    );
  }

  if (rewrite == null) {
    rewrite = (rewritesPathnameMap[externalHref.pathname] ?? []).find(r =>
      r.keys.every(
        key => key.modifier === '?' || key.name in externalHref.query,
      ),
    );
  }

  if (rewrite == null) {
    const as = format({
      pathname: hasProtocol(externalHref.pathname)
        ? externalHref.pathname
        : fixAsPathname(externalHref.pathname, language),
      query: externalHref.query,
    });
    return { href: internalHref, as };
  }

  return {
    href: {
      pathname: internalHref.pathname,
      query: {
        ...internalHref.query,
        ...rewrite.query,
        ...(rewrite.lang != null ? { lang: rewrite.lang } : null),
        ...sourceQuery,
      },
    },
    as: format({
      pathname: fixAsPathname(
        generatePath(rewrite.path, externalHref.query),
        language,
      ),
      // remove keys from query if they are already exists in url params or in rewrite.query
      // or remove lang if rewrite has it
      query: omitBy(
        externalHref.query,
        (_, key) =>
          ((rewrite || {}).keys || []).some(k => k.name === key) ||
          Object.keys((rewrite || {}).query || {}).some(k => k === key) ||
          (rewrite != null && rewrite.lang != null && key === 'lang'),
      ),
    }),
  };
};
