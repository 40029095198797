// @flow

import * as React from 'react';
import type { UserData } from '../server/UserData.js';

type Props = {|
  value: UserData,
  children: React.Node,
|};

const UserDataContext = React.createContext<?UserData>(null);

export const UserDataProvider = ({ value, children }: Props): React.Node => {
  return (
    <UserDataContext.Provider value={value}>
      {children}
    </UserDataContext.Provider>
  );
};

export const useUserData = (): UserData => {
  const userData = React.useContext(UserDataContext);
  if (userData == null) {
    throw Error('invariant');
  }
  return userData;
};
