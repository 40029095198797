// @flow

import fastMemoize from 'fast-memoize';

export const memoize = <T: (...args: $ReadOnlyArray<any>) => any>(fn: T): T => {
  return fastMemoize(fn, {
    cache: {
      create() {
        let value_ = null;
        let key_ = null;
        return {
          has(key) {
            return key === key_;
          },
          get(key) {
            if (key !== key_) {
              return undefined;
            }
            return value_;
          },
          set(key, value) {
            key_ = key;
            value_ = value;
          },
        };
      },
    },
  });
};
