// @flow

import * as muiColors from '@material-ui/core/colors';

const baseColors = {
  // common
  black: muiColors.common.black,
  white: muiColors.common.white,

  // amber
  amber50: (muiColors.amber['50']: string),
  amber100: (muiColors.amber['100']: string),
  amber200: (muiColors.amber['200']: string),
  amber300: (muiColors.amber['300']: string),
  amber400: (muiColors.amber['400']: string),
  amber500: (muiColors.amber['500']: string),
  amber600: (muiColors.amber['600']: string),
  amber700: (muiColors.amber['700']: string),
  amber800: (muiColors.amber['800']: string),
  amber900: (muiColors.amber['900']: string),
  amberA100: (muiColors.amber['A100']: string),
  amberA200: (muiColors.amber['A200']: string),
  amberA400: (muiColors.amber['A400']: string),
  amberA700: (muiColors.amber['A700']: string),

  // blue
  blue50: (muiColors.blue['50']: string),
  blue100: (muiColors.blue['100']: string),
  blue200: (muiColors.blue['200']: string),
  blue300: (muiColors.blue['300']: string),
  blue400: (muiColors.blue['400']: string),
  blue500: (muiColors.blue['500']: string),
  blue600: (muiColors.blue['600']: string),
  blue700: (muiColors.blue['700']: string),
  blue800: (muiColors.blue['800']: string),
  blue900: (muiColors.blue['900']: string),
  blueA100: (muiColors.blue['A100']: string),
  blueA200: (muiColors.blue['A200']: string),
  blueA400: (muiColors.blue['A400']: string),
  blueA700: (muiColors.blue['A700']: string),

  // blueGrey
  blueGrey50: (muiColors.blueGrey['50']: string),
  blueGrey100: (muiColors.blueGrey['100']: string),
  blueGrey200: (muiColors.blueGrey['200']: string),
  blueGrey300: (muiColors.blueGrey['300']: string),
  blueGrey400: (muiColors.blueGrey['400']: string),
  blueGrey500: (muiColors.blueGrey['500']: string),
  blueGrey600: (muiColors.blueGrey['600']: string),
  blueGrey700: (muiColors.blueGrey['700']: string),
  blueGrey800: (muiColors.blueGrey['800']: string),
  blueGrey900: (muiColors.blueGrey['900']: string),
  blueGreyA100: (muiColors.blueGrey['A100']: string),
  blueGreyA200: (muiColors.blueGrey['A200']: string),
  blueGreyA400: (muiColors.blueGrey['A400']: string),
  blueGreyA700: (muiColors.blueGrey['A700']: string),

  // brown
  brown50: (muiColors.brown['50']: string),
  brown100: (muiColors.brown['100']: string),
  brown200: (muiColors.brown['200']: string),
  brown300: (muiColors.brown['300']: string),
  brown400: (muiColors.brown['400']: string),
  brown500: (muiColors.brown['500']: string),
  brown600: (muiColors.brown['600']: string),
  brown700: (muiColors.brown['700']: string),
  brown800: (muiColors.brown['800']: string),
  brown900: (muiColors.brown['900']: string),
  brownA100: (muiColors.brown['A100']: string),
  brownA200: (muiColors.brown['A200']: string),
  brownA400: (muiColors.brown['A400']: string),
  brownA700: (muiColors.brown['A700']: string),

  // cyan
  cyan50: (muiColors.cyan['50']: string),
  cyan100: (muiColors.cyan['100']: string),
  cyan200: (muiColors.cyan['200']: string),
  cyan300: (muiColors.cyan['300']: string),
  cyan400: (muiColors.cyan['400']: string),
  cyan500: (muiColors.cyan['500']: string),
  cyan600: (muiColors.cyan['600']: string),
  cyan700: (muiColors.cyan['700']: string),
  cyan800: (muiColors.cyan['800']: string),
  cyan900: (muiColors.cyan['900']: string),
  cyanA100: (muiColors.cyan['A100']: string),
  cyanA200: (muiColors.cyan['A200']: string),
  cyanA400: (muiColors.cyan['A400']: string),
  cyanA700: (muiColors.cyan['A700']: string),

  // deepOrange
  deepOrange50: (muiColors.deepOrange['50']: string),
  deepOrange100: (muiColors.deepOrange['100']: string),
  deepOrange200: (muiColors.deepOrange['200']: string),
  deepOrange300: (muiColors.deepOrange['300']: string),
  deepOrange400: (muiColors.deepOrange['400']: string),
  deepOrange500: (muiColors.deepOrange['500']: string),
  deepOrange600: (muiColors.deepOrange['600']: string),
  deepOrange700: (muiColors.deepOrange['700']: string),
  deepOrange800: (muiColors.deepOrange['800']: string),
  deepOrange900: (muiColors.deepOrange['900']: string),
  deepOrangeA100: (muiColors.deepOrange['A100']: string),
  deepOrangeA200: (muiColors.deepOrange['A200']: string),
  deepOrangeA400: (muiColors.deepOrange['A400']: string),
  deepOrangeA700: (muiColors.deepOrange['A700']: string),

  // deepPurple
  deepPurple50: (muiColors.deepPurple['50']: string),
  deepPurple100: (muiColors.deepPurple['100']: string),
  deepPurple200: (muiColors.deepPurple['200']: string),
  deepPurple300: (muiColors.deepPurple['300']: string),
  deepPurple400: (muiColors.deepPurple['400']: string),
  deepPurple500: (muiColors.deepPurple['500']: string),
  deepPurple600: (muiColors.deepPurple['600']: string),
  deepPurple700: (muiColors.deepPurple['700']: string),
  deepPurple800: (muiColors.deepPurple['800']: string),
  deepPurple900: (muiColors.deepPurple['900']: string),
  deepPurpleA100: (muiColors.deepPurple['A100']: string),
  deepPurpleA200: (muiColors.deepPurple['A200']: string),
  deepPurpleA400: (muiColors.deepPurple['A400']: string),
  deepPurpleA700: (muiColors.deepPurple['A700']: string),

  // green
  green50: (muiColors.green['50']: string),
  green100: (muiColors.green['100']: string),
  green200: (muiColors.green['200']: string),
  green300: (muiColors.green['300']: string),
  green400: (muiColors.green['400']: string),
  green500: (muiColors.green['500']: string),
  green600: (muiColors.green['600']: string),
  green700: (muiColors.green['700']: string),
  green800: (muiColors.green['800']: string),
  green900: (muiColors.green['900']: string),
  greenA100: (muiColors.green['A100']: string),
  greenA200: (muiColors.green['A200']: string),
  greenA400: (muiColors.green['A400']: string),
  greenA700: (muiColors.green['A700']: string),

  // grey
  grey50: (muiColors.grey['50']: string),
  grey100: (muiColors.grey['100']: string),
  grey200: (muiColors.grey['200']: string),
  grey300: (muiColors.grey['300']: string),
  grey400: (muiColors.grey['400']: string),
  grey500: (muiColors.grey['500']: string),
  grey600: (muiColors.grey['600']: string),
  grey700: (muiColors.grey['700']: string),
  grey800: (muiColors.grey['800']: string),
  grey900: (muiColors.grey['900']: string),
  greyA100: (muiColors.grey['A100']: string),
  greyA200: (muiColors.grey['A200']: string),
  greyA400: (muiColors.grey['A400']: string),
  greyA700: (muiColors.grey['A700']: string),

  // indigo
  indigo50: (muiColors.indigo['50']: string),
  indigo100: (muiColors.indigo['100']: string),
  indigo200: (muiColors.indigo['200']: string),
  indigo300: (muiColors.indigo['300']: string),
  indigo400: (muiColors.indigo['400']: string),
  indigo500: (muiColors.indigo['500']: string),
  indigo600: (muiColors.indigo['600']: string),
  indigo700: (muiColors.indigo['700']: string),
  indigo800: (muiColors.indigo['800']: string),
  indigo900: (muiColors.indigo['900']: string),
  indigoA100: (muiColors.indigo['A100']: string),
  indigoA200: (muiColors.indigo['A200']: string),
  indigoA400: (muiColors.indigo['A400']: string),
  indigoA700: (muiColors.indigo['A700']: string),

  // lightBlue
  lightBlue50: (muiColors.lightBlue['50']: string),
  lightBlue100: (muiColors.lightBlue['100']: string),
  lightBlue200: (muiColors.lightBlue['200']: string),
  lightBlue300: (muiColors.lightBlue['300']: string),
  lightBlue400: (muiColors.lightBlue['400']: string),
  lightBlue500: (muiColors.lightBlue['500']: string),
  lightBlue600: (muiColors.lightBlue['600']: string),
  lightBlue700: (muiColors.lightBlue['700']: string),
  lightBlue800: (muiColors.lightBlue['800']: string),
  lightBlue900: (muiColors.lightBlue['900']: string),
  lightBlueA100: (muiColors.lightBlue['A100']: string),
  lightBlueA200: (muiColors.lightBlue['A200']: string),
  lightBlueA400: (muiColors.lightBlue['A400']: string),
  lightBlueA700: (muiColors.lightBlue['A700']: string),

  // lightGreen
  lightGreen50: (muiColors.lightGreen['50']: string),
  lightGreen100: (muiColors.lightGreen['100']: string),
  lightGreen200: (muiColors.lightGreen['200']: string),
  lightGreen300: (muiColors.lightGreen['300']: string),
  lightGreen400: (muiColors.lightGreen['400']: string),
  lightGreen500: (muiColors.lightGreen['500']: string),
  lightGreen600: (muiColors.lightGreen['600']: string),
  lightGreen700: (muiColors.lightGreen['700']: string),
  lightGreen800: (muiColors.lightGreen['800']: string),
  lightGreen900: (muiColors.lightGreen['900']: string),
  lightGreenA100: (muiColors.lightGreen['A100']: string),
  lightGreenA200: (muiColors.lightGreen['A200']: string),
  lightGreenA400: (muiColors.lightGreen['A400']: string),
  lightGreenA700: (muiColors.lightGreen['A700']: string),

  // lime
  lime50: (muiColors.lime['50']: string),
  lime100: (muiColors.lime['100']: string),
  lime200: (muiColors.lime['200']: string),
  lime300: (muiColors.lime['300']: string),
  lime400: (muiColors.lime['400']: string),
  lime500: (muiColors.lime['500']: string),
  lime600: (muiColors.lime['600']: string),
  lime700: (muiColors.lime['700']: string),
  lime800: (muiColors.lime['800']: string),
  lime900: (muiColors.lime['900']: string),
  limeA100: (muiColors.lime['A100']: string),
  limeA200: (muiColors.lime['A200']: string),
  limeA400: (muiColors.lime['A400']: string),
  limeA700: (muiColors.lime['A700']: string),

  // orange
  orange50: (muiColors.orange['50']: string),
  orange100: (muiColors.orange['100']: string),
  orange200: (muiColors.orange['200']: string),
  orange300: (muiColors.orange['300']: string),
  orange400: (muiColors.orange['400']: string),
  orange500: (muiColors.orange['500']: string),
  orange600: (muiColors.orange['600']: string),
  orange700: (muiColors.orange['700']: string),
  orange800: (muiColors.orange['800']: string),
  orange900: (muiColors.orange['900']: string),
  orangeA100: (muiColors.orange['A100']: string),
  orangeA200: (muiColors.orange['A200']: string),
  orangeA400: (muiColors.orange['A400']: string),
  orangeA700: (muiColors.orange['A700']: string),

  // pink
  pink50: (muiColors.pink['50']: string),
  pink100: (muiColors.pink['100']: string),
  pink200: (muiColors.pink['200']: string),
  pink300: (muiColors.pink['300']: string),
  pink400: (muiColors.pink['400']: string),
  pink500: (muiColors.pink['500']: string),
  pink600: (muiColors.pink['600']: string),
  pink700: (muiColors.pink['700']: string),
  pink800: (muiColors.pink['800']: string),
  pink900: (muiColors.pink['900']: string),
  pinkA100: (muiColors.pink['A100']: string),
  pinkA200: (muiColors.pink['A200']: string),
  pinkA400: (muiColors.pink['A400']: string),
  pinkA700: (muiColors.pink['A700']: string),

  // purple
  purple50: (muiColors.purple['50']: string),
  purple100: (muiColors.purple['100']: string),
  purple200: (muiColors.purple['200']: string),
  purple300: (muiColors.purple['300']: string),
  purple400: (muiColors.purple['400']: string),
  purple500: (muiColors.purple['500']: string),
  purple600: (muiColors.purple['600']: string),
  purple700: (muiColors.purple['700']: string),
  purple800: (muiColors.purple['800']: string),
  purple900: (muiColors.purple['900']: string),
  purpleA100: (muiColors.purple['A100']: string),
  purpleA200: (muiColors.purple['A200']: string),
  purpleA400: (muiColors.purple['A400']: string),
  purpleA700: (muiColors.purple['A700']: string),

  // red
  red50: (muiColors.red['50']: string),
  red100: (muiColors.red['100']: string),
  red200: (muiColors.red['200']: string),
  red300: (muiColors.red['300']: string),
  red400: (muiColors.red['400']: string),
  red500: (muiColors.red['500']: string),
  red600: (muiColors.red['600']: string),
  red700: (muiColors.red['700']: string),
  red800: (muiColors.red['800']: string),
  red900: (muiColors.red['900']: string),
  redA100: (muiColors.red['A100']: string),
  redA200: (muiColors.red['A200']: string),
  redA400: (muiColors.red['A400']: string),
  redA700: (muiColors.red['A700']: string),

  // teal
  teal50: (muiColors.teal['50']: string),
  teal100: (muiColors.teal['100']: string),
  teal200: (muiColors.teal['200']: string),
  teal300: (muiColors.teal['300']: string),
  teal400: (muiColors.teal['400']: string),
  teal500: (muiColors.teal['500']: string),
  teal600: (muiColors.teal['600']: string),
  teal700: (muiColors.teal['700']: string),
  teal800: (muiColors.teal['800']: string),
  teal900: (muiColors.teal['900']: string),
  tealA100: (muiColors.teal['A100']: string),
  tealA200: (muiColors.teal['A200']: string),
  tealA400: (muiColors.teal['A400']: string),
  tealA700: (muiColors.teal['A700']: string),

  // yellow
  yellow50: (muiColors.yellow['50']: string),
  yellow100: (muiColors.yellow['100']: string),
  yellow200: (muiColors.yellow['200']: string),
  yellow300: (muiColors.yellow['300']: string),
  yellow400: (muiColors.yellow['400']: string),
  yellow500: (muiColors.yellow['500']: string),
  yellow600: (muiColors.yellow['600']: string),
  yellow700: (muiColors.yellow['700']: string),
  yellow800: (muiColors.yellow['800']: string),
  yellow900: (muiColors.yellow['900']: string),
  yellowA100: (muiColors.yellow['A100']: string),
  yellowA200: (muiColors.yellow['A200']: string),
  yellowA400: (muiColors.yellow['A400']: string),
  yellowA700: (muiColors.yellow['A700']: string),
};

export const colors = {
  ...baseColors,
  // The colors used to style the text.
  highText: 'rgba(0, 0, 0, 0.84)',
  mediumText: 'rgba(0, 0, 0, 0.6)',
  disabledText: 'rgba(0, 0, 0, 0.38)',
  errorText: '#B00020',

  primaryMain: '#3454D1',
  secondaryMain: '#DD435A',
  backgroundMain: '#F5F9FF',

  // additional colors
  divider: 'rgba(0, 0, 0, 0.24)',

  common: muiColors.common,
  red: muiColors.red,
  blue: muiColors.blue,
  teal: muiColors.teal,
  green: muiColors.green,
  orange: muiColors.orange,
  grey: muiColors.grey,
  blueGrey: muiColors.blueGrey,
};
