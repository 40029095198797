// @flow

// IF YOU MOVE THIS FILE UPDATE COMMENT IN realadvisor.api/src/utils/reference.js

const ALPHABET = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ';

// NOTE: we rely on ALPHABET not having any special symbols
const referenceRegExp = new RegExp(`^[${ALPHABET}]{4}-[${ALPHABET}]{4,}$`);

export const isReference = (ref: string): boolean => referenceRegExp.test(ref);
