// @flow

/* eslint-disable react-hooks/exhaustive-deps */

import * as React from 'react';

const useLayoutEffect =
  typeof window !== 'undefined' ? React.useLayoutEffect : React.useEffect;

let noSSR_ = false;

// better to use at project root once so caching will be applied on a first usage
export const useNoSSR = (): boolean => {
  // we cache initial noSSR_ value, so for subsequent uses it will have right values
  const [noSSR, setNoSSR] = React.useState(noSSR_);

  useLayoutEffect(() => {
    if (noSSR === false) {
      setNoSSR(true);
      noSSR_ = true;
    }
  }, []);

  return noSSR;
};
