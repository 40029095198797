// @flow

// Duplicate of aggregator-custom/src/routes/[language]/_components/seo-user-info.svelte
// To have same logic in both projects
import * as React from 'react';
import * as cookie from '../utils/cookie';
import { getStorage } from '../hooks/local-storage';

// Same As in svelte projects
const LOCAL_STORAGE_KEY = '__s_u_i__';

type SeoUserInfo = {|
  // URL
  landing_page_url: null | string,
  // document source
  referrer: null | string,
|};

export const SeoUserInfoInit = (): React.Node => {
  /**
   * We set/change landing page when
   * - on page load if
   *  - referrer is empty - means that user entered page directly in a browser
   *  - referrer is external - means was click from some 3rd party site
   *  - if landing page in local storage is not set at all
   *
   * We set referrer
   * - on page load if its external and not equal to current host
   */
  React.useEffect(() => {
    const url = new URL(location.href);
    const storage = getStorage();

    let referrer = null;
    let landing_page_url = null;

    const referrerUrl = new URL(document.referrer, url.href);

    if (document.referrer === '') {
      // entered page directly, always set landing page
      landing_page_url = url.href;
    }

    if (referrerUrl.host !== url.host) {
      // external referrer
      referrer = document.referrer;
      landing_page_url = url.href;
    }

    // If havent changed during referrer calculation
    if (landing_page_url == null) {
      const landingPagePrevious = storage.getItem(
        `${LOCAL_STORAGE_KEY}landing_page_url`,
      );

      if (landingPagePrevious == null) {
        // set current if no history exists
        landing_page_url = url.href;
      }
    }

    const seoUserInfo: SeoUserInfo = {
      landing_page_url,
      referrer,
    };

    for (const kv of Object.entries(seoUserInfo)) {
      const v = kv[1];
      if (v != null && typeof v === 'string') {
        storage.setItem(`${LOCAL_STORAGE_KEY}${kv[0]}`, v);
      }
    }
  }, []);

  return null;
};

export const useSeoUserInfo = (): null | $Shape<SeoUserInfo> => {
  const [state, setState] = React.useState<null | $Shape<SeoUserInfo>>(null);

  // TODO: make client side except IP which move to API

  React.useEffect(() => {
    const s: $Shape<SeoUserInfo> = {};
    const storage = getStorage();

    for (const k of ['landing_page_url', 'referrer']) {
      const c = cookie.get(k);
      if (c != null) {
        s[k] = c;
      }

      if (s[k] == null) {
        const v = storage.getItem(`${LOCAL_STORAGE_KEY}${k}`);
        if (v != null) {
          s[k] = v;
        }
      }
    }

    setState(s);
  }, []);

  return state;
};
